import { Component, OnInit } from '@angular/core';
import * as WSCall from "@app/utils/WSCall";
import {
  MySessionResult,
  RegisterSessionControllerService,
  RegisterSessionV2ControllerService,
  ResultSessionModel,
  ResultSessionNonProflinkModel,
  TrainingDateModel
} from '@proflink/prof-link-web-ic-api-ts-angular';
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.scss']
})

export class MyCourseComponent implements OnInit {

  sessions: Array<MySessionResult> = [];
  sessionTypeEnum = MySessionResult.SessionTypeEnum;
  currentDate: number = new Date().setHours(0, 0, 0, 0);
  language: string;

  constructor(
    private _registerSessionController: RegisterSessionControllerService,
    private _registerSessionV2Controller: RegisterSessionV2ControllerService,
    private _datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
    this._registerSessionV2Controller.myAllSessionV2UsingGET().subscribe(res => {
      this.sessions = res.data
    });
    if (this.activatedRoute.snapshot.queryParamMap.get('openLastResultDetail')) {
      this.openLastDetail();
    }
  }

  displayDate(item: MySessionResult): string {
    let displayDate: string = '';
    if (item.startTrainingDate && item.endTrainingDate) {
      let firstTrainingDate: Date = new Date(item.startTrainingDate);
      let lastTrainingDate: Date = new Date(item.endTrainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
      if (firstDayDisplay == lastDayDisplay) {
        displayDate = firstDayDisplay + ' ' + yearTraining;
      } else {
        displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
      }
    } else {
      let trainingDate: Date = new Date(item.startTrainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
      displayDate = birthDaySubmit + ' ' + yearTraining;
    }

    return displayDate;
  }

  openLastDetail(): void {
    const resultSessionSelected = sessionStorage.getItem('resultSessionSelected');
    const result = JSON.parse(resultSessionSelected);
    if (result) {
      this.onSelectResultSession(result);
    }
  }

  async getMyAllSessionByIcId(): Promise<Array<ResultSessionModel>> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getMyAllSessionByIcIdUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getMyAllSessionNonProflink(): Promise<Array<ResultSessionNonProflinkModel>> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getMyAllSessionNonProflinkUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  onSelectSession(item: MySessionResult): void {
    if (item.sessionId) {
      this._router.navigate([`/my-course-detail-share/${item.sessionId}/1/mycourse`]);
    }
  }

  onSelectResultSession(resultSessionModel: ResultSessionModel) {
    sessionStorage.setItem('resultSessionSelected', JSON.stringify(resultSessionModel));
    if (resultSessionModel.sessionId) {
      this._router.navigate([`/my-course-detail-share/${resultSessionModel.sessionId}/1/mycourse`]);
    }
  }

  getPeriodTrainingDayDisplay(trainingdays: Array<TrainingDateModel>) {

    let displayDate: string = '';
    if (trainingdays.length <= 1) {

      let trainingDate: Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
      displayDate = birthDaySubmit + ' ' + yearTraining;

    } else {

      let firstTrainingDate: Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate: Date = new Date(trainingdays[trainingdays.length - 1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM', null, this.language == 'en' ? 'en' : null);

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = this.language == 'th' ? yearTraining + 543 : yearTraining;
      displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
    }

    return displayDate;
  }

}
