<app-header-with-title class="header" [displayTitle]="displayTitle" [title]="title"></app-header-with-title>
<div class="box-container">
    <div class="box">
        <div *ngIf="registerState == 'AGREE_CONSENT'">
            <div class="form-group">
                <app-ic-consent (agreeConsent)="onAgreeConsent($event)">
                </app-ic-consent>
            </div>
        </div>

        <div *ngIf="registerState != 'AGREE_CONSENT'" class="container content">
            <div class="form-group">

                <app-instructional-share *ngIf="registerState == 'INSTRUCTIONAL'"
                                         (completeState)="onCompleteInstruction($event)">

                </app-instructional-share>

                <app-register-form *ngIf="registerState == 'CHECK_IC' && !isForeigner"
                                   (requestRegisterEmit)="onCompleteRegisterForm($event)"
                                   [consentVersionNo]="consentVersionNo"
                ></app-register-form>

                <app-register-foreigner-form *ngIf="registerState == 'CHECK_IC' && isForeigner"
                                   (requestRegisterEmit)="onCompleteRegisterForeignerForm($event)"
                                   [consentVersionNo]="consentVersionNo"
                                   [fromProfile]="requestRegisterForeigner"
                ></app-register-foreigner-form>

                <app-register-form-result *ngIf="registerState == 'CONFIRM_IC' && !isForeigner"
                                          [icProfile]="icProfile"
                                          [requestRegister]="requestRegister"
                                          (completeState)="onCompleteConfirmRegister($event)"></app-register-form-result>

                <app-register-foreigner-form-result *ngIf="registerState == 'CONFIRM_IC' && isForeigner"
                                          [icProfile]="icProfile"
                                          [requestRegisterForeigner]="requestRegisterForeigner"
                                          (completeState)="onSubmitCreateUserForeigner($event)"></app-register-foreigner-form-result>
                <app-regiseter-form-create *ngIf="registerState == 'CREATE_USER'"
                                           [icProfile]="icProfile"
                                           (completeState)="onSubmitCreateUser($event)"></app-regiseter-form-create>

                <app-otp-share *ngIf="registerState == 'OTP' && !isForeigner"
                               (successVerifyOtp)="onVerifyOTPSuccess($event)"
                               [resultOTP]="resultOTPModel"
                               pathBack="login"></app-otp-share>

                <app-otp-foreigner *ngIf="registerState == 'OTP' && isForeigner"
                               (successVerifyOtp)="onVerifyOTPForeigerSuccess($event)"
                               [resultOTP]="resultOTPModel"
                               pathBack="login"></app-otp-foreigner>

                <!--            <app-create-pin *ngIf="registerState == 'CREATE_PIN'"-->
                <!--                [otpToken]="token"-->
                <!--                [username]="icProfile.idCard"-->
                <!--            ></app-create-pin>-->
                <app-create-pin-share *ngIf="registerState == 'CREATE_PIN'"
                                      [otpToken]="token"
                                      [laserId]="requestRegister.laserId"
                                      [icProfile]="icProfile"
                                      [createType]="createType"
                                      (completeState)="onSubmitCreatePIN($event)">

                </app-create-pin-share>

                <app-instructional-license-share *ngIf="registerState == 'INSTRUCTIONAL_LICENSE'"
                                         (completeState)="onCompleteInstructionLicense($event)">
                </app-instructional-license-share>

                <app-register-foreigner-wait-approve *ngIf="registerState == 'WAIT_APPROVAL'"
                                       [mobileNumber]="mobileNumber">
                </app-register-foreigner-wait-approve>

            </div>
        </div>
    </div>
</div>
<!-- <div class="content-wrapper m-0 p-0 custom-consent col-sm-12 text-center custom-input">
    <div class="col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4 pt-5">


        <div *ngIf="registerState == 'AGREE_CONSENT'">
            <div class="form-group">
                <app-ic-consent (agreeConsent)="onAgreeConsent($event)">
                </app-ic-consent>
            </div>
        </div>

        <div *ngIf="registerState != 'AGREE_CONSENT'" class="container content">
            <div class="form-group">

                <app-instructional-share *ngIf="registerState == 'INSTRUCTIONAL'"
                                         (completeState)="onCompleteInstruction($event)">

                </app-instructional-share>

                <app-register-form *ngIf="registerState == 'CHECK_IC'"
                                   (requestRegisterEmit)="onCompleteRegisterForm($event)"
                                   [consentVersionNo]="consentVersionNo"
                ></app-register-form>

                <app-register-form-result *ngIf="registerState == 'CONFIRM_IC'"
                                          [icProfile]="icProfile"
                                          [requestRegister]="requestRegister"
                                          (completeState)="onCompleteConfirmRegister($event)"></app-register-form-result>
                <app-regiseter-form-create *ngIf="registerState == 'CREATE_USER'"
                                           [icProfile]="icProfile"
                                           (completeState)="onSubmitCreateUser($event)"></app-regiseter-form-create>

                <app-otp-share *ngIf="registerState == 'OTP'"
                               (successVerifyOtp)="onVerifyOTPSuccess($event)"
                               [resultOTP]="resultOTPModel"
                               pathBack="login"></app-otp-share>

                <app-create-pin-share *ngIf="registerState == 'CREATE_PIN'"
                                      [otpToken]="token"
                                      [laserId]="requestRegister.laserId"
                                      [icProfile]="icProfile"
                                      [createType]="createType"
                                      (completeState)="onSubmitCreatePIN($event)">

                </app-create-pin-share>

                <app-instructional-license-share *ngIf="registerState == 'INSTRUCTIONAL_LICENSE'"
                                         (completeState)="onCompleteInstructionLicense($event)">
                </app-instructional-license-share>

            </div>
        </div>
    </div>


</div> -->

