import { Component, Input, OnInit } from '@angular/core';
import { ResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import { SecCheckIcThaiQueryModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcThaiQueryModel";
import { ConsentVersionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service";
import { AccountControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service';
import { IcAgreeConsentLogSaveRequest } from "@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogSaveRequest";
import { APIResultboolean } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean";
import * as WSCall from "@app/utils/WSCall";
import { IcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import { RegisterICProfileRequest } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICProfileRequest";
import { APIResultIcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultIcProfileModel";
import * as Utils from "@app/utils/Utils";
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { APIResultResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel";
import { CreateICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/createICTemporaryAccountModel";
import { APIResultstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import { sha256 } from "js-sha256";
import { Router } from "@angular/router";
import { AppState } from "@core/app-state";
import { AuthProvider } from "@core/auth/auth-provider.service";
import { SecCheckIcForeignerQueryModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcForeignerQueryModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private _accountControllerService: AccountControllerService,
    private _router: Router,
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _translate: TranslateService
  ) { }


  displayTitle: boolean = false;
  title: string = '';

  registerState: RegisterStateEnum = RegisterStateEnum.INSTRUCTIONAL;

  consentVersionNo: string = '';

  resultOTPModel: ResultOTPModel
  token: string = '';
  verifySuccess = false;
  requestRegister: SecCheckIcThaiQueryModel;
  icProfile: IcProfileModel;
  url: string;
  createType: string = "1";
  createICTemporaryAccountModel: CreateICTemporaryAccountModel;
  email: string = '';
  mobileNumber: string = '';

  submitForm = false;
  isForeigner: boolean = false;

  requestRegisterForeigner: SecCheckIcForeignerQueryModel;
  language: string = 'th';
  registerICProfileRequest: RegisterICProfileRequest = {
    idCardNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthday: "",
    laserId: "",
    uniqueToken: "",
    idCardExpireDate: "",
    birthdateFormat: ""
  };

  ngOnInit() {
    this.language = localStorage.getItem('language');
    this._translate.use(this.language);

    this.isForeigner = localStorage.getItem('type_user') == 'foreigner';
    this.displayTitle = true;
    this._translate.get('REGISTER_PAGE.T_RECOMMEND_VIDEO').subscribe((res: string) => {
      this.title = res;
    });
    this.url = "https://www.youtube.com/watch?v=IPCiyD6RlQw";
    if (this.isForeigner == true) {
      this._translate.get('REGISTER_PAGE.T_TERM_AND_CONTITION').subscribe((res: string) => {
        this.title = res;
      });
      this.registerState = RegisterStateEnum.AGREE_CONSENT;
    }
  }

  onAgreeConsent(consentVersionNo: string) {
    this.consentVersionNo = consentVersionNo;
    this.registerState = RegisterStateEnum.CHECK_IC;
    // MOCK
    // this.registerState = RegisterStateEnum.WAIT_APPROVAL;
    this.displayTitle = true;
    this._translate.get('REGISTER_PAGE.T_CREATE').subscribe((res: string) => {
      this.title = res;
    });
  }


  async onSubmitCreateUser(registerICTemporaryAccountModel: RegisterICTemporaryAccountModel) {

    let result: APIResultResultOTPModel = await this.generateOTP(registerICTemporaryAccountModel);
    if (result.resultCode == 'SUCCESS') {

      this.email = registerICTemporaryAccountModel.email;
      this.mobileNumber = registerICTemporaryAccountModel.mobileNumber;
      this.resultOTPModel = result.data;
      this.registerState = RegisterStateEnum.OTP;
      this.displayTitle = true;
      this._translate.get('REGISTER_PAGE.T_CONFIRM_OTP').subscribe((res: string) => {
        this.title = res;
      });
    } else {
      Utils.error(result.errorMessage);
    }

  }

  onCompleteState(state: string) {

    this.registerState = RegisterStateEnum[state];
  }

  async onCompleteConfirmRegister(registerICProfileRequest: RegisterICProfileRequest) {

    let result: APIResultIcProfileModel = await this.matchSecIcProfileThai(registerICProfileRequest);
    if (result.resultCode == 'SUCCESS') {
      this.icProfile = result.data;
      this.registerState = RegisterStateEnum.CREATE_USER;
      this.displayTitle = true;
      this._translate.get('REGISTER_PAGE.T_CREATE').subscribe((res: string) => {
        this.title = res;
      });
    } else {
      Utils.error(result.errorMessage);
    }
  }

  // STEP 1 CHECK IC
  async onCompleteRegisterForeignerForm(requestRegisterForeigner: SecCheckIcForeignerQueryModel) {
    this.requestRegisterForeigner = requestRegisterForeigner;
    let result: APIResultIcProfileModel = await this.matchSecIcForeigner(requestRegisterForeigner);
    if (result.resultCode == 'SUCCESS') {
      this.registerICProfileRequest.icCode = requestRegisterForeigner.icCode;
      this.registerICProfileRequest.nameTitleId = Number(requestRegisterForeigner.title);
      this.registerICProfileRequest.firstName = requestRegisterForeigner.firstName;
      this.registerICProfileRequest.middleName = requestRegisterForeigner.middleName;
      this.registerICProfileRequest.lastName = requestRegisterForeigner.lastName;
      this.registerICProfileRequest.nationalityId = requestRegisterForeigner.nationalityId;
      this.registerICProfileRequest.passport = requestRegisterForeigner.passportNo;
      this.registerICProfileRequest.dateExpire = requestRegisterForeigner.dateOfExpiry;
      this.registerICProfileRequest.birthday = requestRegisterForeigner.birthday;
      this.registerICProfileRequest.uniqueToken = requestRegisterForeigner.uniqueToken;
      this.registerICProfileRequest.gender = Number(requestRegisterForeigner.gender);
      this.registerICProfileRequest.createType = "2";
      this.registerICProfileRequest.attachedProfileFileId = requestRegisterForeigner.facePhoto.fileId;
      this.registerICProfileRequest.attachedDocumentFileId = requestRegisterForeigner.passportPhoto.fileId;

      await this.onCompleteConfirmRegisterForeigner();
    } else {
      Utils.error(result.errorMessage);
    }
  }

  // STEP 2 UPDATE IC
  async onCompleteConfirmRegisterForeigner() {
    let result: APIResultIcProfileModel = await this.matchSecIcProfileForeigner(this.registerICProfileRequest);
    if (result.resultCode == 'SUCCESS') {
      this.icProfile = result.data;
      this.registerState = RegisterStateEnum.CONFIRM_IC;
      this.displayTitle = true;
      this.title = 'Create a Prof. Link Profile';
    } else {
      Utils.error(result.errorMessage);
    }
  }

  // STEP 3 VERIFY OTP
  async onSubmitCreateUserForeigner(event: Boolean) {
    if (event) {
      // MOCK
      // let registerICTemporaryAccountModel: RegisterICTemporaryAccountModel = {};
      let registerICTemporaryAccountModel: RegisterICTemporaryAccountModel = {
        mobileNumber: this.requestRegisterForeigner.mobileNumber,
        email: this.requestRegisterForeigner.email,
        otpType: "RE",
        icId: this.icProfile.icId
      };
      let result: APIResultResultOTPModel = await this.generateOTP(registerICTemporaryAccountModel);
      if (result.resultCode == 'SUCCESS') {
        this.resultOTPModel = result.data;
        this.registerState = RegisterStateEnum.OTP;
        this.displayTitle = true;
        this._translate.get('REGISTER_PAGE.T_CREATE_TEMP').subscribe((res: string) => {
          this.title = res;
        });
        this.mobileNumber = this.requestRegisterForeigner.mobileNumber;
      } else {
        Utils.error(result.errorMessage);
      }
    } else {
      this.registerState = RegisterStateEnum.CHECK_IC;
    }
  }

  async onCompleteRegisterForm(requestRegister: SecCheckIcThaiQueryModel) {
    this.requestRegister = requestRegister;
    let result: APIResultIcProfileModel = await this.matchSecIcThai(requestRegister);
    if (result.resultCode == 'SUCCESS') {
      this.icProfile = result.data;
      this.registerState = RegisterStateEnum.CONFIRM_IC;
      this.displayTitle = true;
      this._translate.get('REGISTER_PAGE.T_RESULT').subscribe((res: string) => {
        this.title = res;
      });
    } else {
      Utils.error(result.errorMessage);
    }
  }


  async onCompleteInstruction(complete: boolean) {
    this.registerState = RegisterStateEnum.AGREE_CONSENT;
    this._translate.get('REGISTER_PAGE.T_TERM_AND_CONTITION').subscribe((res: string) => {
      this.title = res;
    });
  }

  async onCompleteInstructionLicense(complete: boolean) {

    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    this._appState.login(this.icProfile.idCard, this.createICTemporaryAccountModel.password, deviceToken)
      .then(() => {
        this._router.navigate(["home"]);
      })
      .catch(reason => {
        let errorText = '';
        this._translate.get('UTILS.ERROR').subscribe((res: string) => {
          errorText = res;
        });
        Utils.error(errorText);
      });
  }

  async onSubmitCreatePIN(createICTemporaryAccountModel: CreateICTemporaryAccountModel) {
    createICTemporaryAccountModel.email = this.email;
    let result: APIResultstring = await this.addTempAccount(createICTemporaryAccountModel);
    if (result.resultCode == 'SUCCESS') {
      this.createICTemporaryAccountModel = createICTemporaryAccountModel;
      this.registerState = RegisterStateEnum.INSTRUCTIONAL_LICENSE;
      this.displayTitle = true;
      this._translate.get('REGISTER_PAGE.T_VERIFY_INFORMATION').subscribe((res: string) => {
        this.title = res;
      });
    } else {
      Utils.error(result.errorMessage);
    }
  }

  onVerifyOTPSuccess(token: string) {

    this.token = token;
    this.verifySuccess = true;
    this.registerState = RegisterStateEnum.CREATE_PIN;
    this.displayTitle = true;
    this._translate.get('REGISTER_PAGE.T_CREATE_PIN').subscribe((res: string) => {
      this.title = res;
    });
  }

  // STEP 4 SUCCESS OTP AND CREATE TEMP ACCOUNT
  async onVerifyOTPForeigerSuccess(token: string) {
    if (token != null && token != '') {
      let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
      let password = Math.floor(Math.random() * 900000) + 100000;
      let createICTemporaryAccountModel: CreateICTemporaryAccountModel = {
        icId: this.icProfile.icId,
        otpToken: token,
        password: sha256(password.toString()),
        uniqueToken: deviceToken,
        email: this.requestRegisterForeigner.email,
        createType: "2",
        nationalityId: this.requestRegisterForeigner.nationalityId,
        gender: Number(this.requestRegisterForeigner.gender),
        nameTitleId: Number(this.requestRegisterForeigner.title),
      };
      let result: APIResultstring = await this.addTempAccount(createICTemporaryAccountModel);
      if (result.resultCode == 'SUCCESS') {
        this.onAddApprovalAccount();
      } else {
        Utils.error(result.errorMessage);
      }
    } else {
      await this.onCompleteConfirmRegisterForeigner();
    }
  }

  // STEP 5 ADD APPROVAL ACCOUNT IN TABLE
  async onAddApprovalAccount() {
    let result: APIResultstring = await this.addApprovalAccount(this.icProfile.icId);
    if (result.resultCode == 'SUCCESS') {
      this.registerState = RegisterStateEnum.WAIT_APPROVAL;
      this.displayTitle = true;
      this._translate.get('REGISTER_PAGE.T_CREATE_TEMP').subscribe((res: string) => {
        this.title = res;
      });
    } else {
      Utils.error(result.errorMessage);
    }
  }

  async matchSecIcThai(secCheckIcThaiQueryModel: SecCheckIcThaiQueryModel): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.matchSecIcThaiUsingPOST(secCheckIcThaiQueryModel)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async matchSecIcProfileThai(registerICProfileRequest: RegisterICProfileRequest): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.matchSecIcProfileThaiUsingPOST(registerICProfileRequest)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async matchSecIcForeigner(secCheckIcForeignerQueryModel: SecCheckIcForeignerQueryModel): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.matchSecIcForeignerUsingPOST(secCheckIcForeignerQueryModel)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async matchSecIcProfileForeigner(registerICProfileRequest: RegisterICProfileRequest): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.matchSecIcProfileForeignerUsingPOST(registerICProfileRequest)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async generateOTP(registerICTemporaryAccountModel: RegisterICTemporaryAccountModel): Promise<APIResultResultOTPModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.generateOTPUsingPOST(registerICTemporaryAccountModel)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async addTempAccount(createICTemporaryAccountModel: CreateICTemporaryAccountModel): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.addTempAccountUsingPOST(createICTemporaryAccountModel)
      },
      respBody => {
        return respBody;
      }
    );
  }

  async addApprovalAccount(icId: number): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.createApprovalForeignerUsingGET(icId)
      },
      respBody => {
        return respBody;
      }
    );
  }

}

export type RegisterStateEnum = 'AGREE_CONSENT' | 'CHECK_IC' | 'CONFIRM_IC' | 'CREATE_USER' | 'OTP' | 'CREATE_PIN' | 'INSTRUCTIONAL'
export const RegisterStateEnum = {
  INSTRUCTIONAL: 'INSTRUCTIONAL' as RegisterStateEnum,
  AGREE_CONSENT: 'AGREE_CONSENT' as RegisterStateEnum,
  CHECK_IC: 'CHECK_IC' as RegisterStateEnum,
  CONFIRM_IC: 'CONFIRM_IC' as RegisterStateEnum,
  CREATE_USER: 'CREATE_USER' as RegisterStateEnum,
  OTP: 'OTP' as RegisterStateEnum,
  CREATE_PIN: 'CREATE_PIN' as RegisterStateEnum,
  INSTRUCTIONAL_LICENSE: 'INSTRUCTIONAL_LICENSE' as RegisterStateEnum,
  WAIT_APPROVAL: 'WAIT_APPROVAL' as RegisterStateEnum

}
