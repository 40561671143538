<div class="d-flex align-items-center justify-content-end nav-language">
    <app-language-switcher [isDisabled]="true"></app-language-switcher>
</div>

<ul *ngIf="!displayTitle" class="nav navbar-light bg-light">
    <li class="nav-item">
        <div class="nav-link">
            <p class="m-0 title-header">Prof.<span class="text-primary"> Link</span></p>
        </div>
    </li>
</ul>

<ul *ngIf="displayTitle" class="nav navbar-light bg-light">
    <li class="nav-item">
        <div class="nav-link"></div>
    </li>
    <li class="nav-item">
        <div class="nav-link">
            <p class="m-0 title-header" [innerHTML]="title"></p>
        </div>
    </li>
    <li class="nav-item">

    </li>
</ul>