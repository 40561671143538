import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CategoryModel, Configuration, CourseActivityControllerService, CourseCategoryModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import * as WSCall from '@app/utils/WSCall';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/app-state';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as Utils from '@app/utils/Utils';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-course-activitity',
  templateUrl: './course-activitity.component.html',
  styleUrls: ['./course-activitity.component.scss']
})
export class CourseActivitityComponent implements OnInit, AfterViewInit {

  datatable: DataTables.Api;
  listOfCategory: CategoryModel[] = [];
  filterForm: FormGroup;
  exportButtonClassId: number = Math.floor(Math.random() * 999999);
  isMobile: boolean = false;
  isTablet: boolean = false;

  selectedCategories: CategoryModel[] = [];
  selectedStatuses: string[] = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'categoryCode',
    textField: 'categoryName',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  drawCallbackBlock: () => void;

  constructor(
    private _appState: AppState,
    private _router: Router,
    private _apiConfig: Configuration,
    private _sanitizer: DomSanitizer,
    private _datePipe: DatePipe,
    private _courseActivityControllerService: CourseActivityControllerService,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    if (this.isMobile || this.isTablet) {
      this.notifyMobileUser();
    } else {
      this.createForm();
      this.getListOfCategory().then((result: CourseCategoryModel[]) => {
        this.listOfCategory = result;
      });
    }
  }
  notifyMobileUser() {
    Utils.infoWithCallBack("เนื่องจากการยื่นขออนุมัติการทำกิจกรรม  (Course Activities) มีการประมวลผลข้อมูลที่ต้องใช้ความละเอียดถี่ถ้วน เพื่อความสะดวกและป้องกันความคลาดเคลื่อนในการยื่นขออนุมัติ กรุณาทำรายการผ่านเครื่อง computer / notebook และใช้ browser Chrome ในการดำเนินการ", "", () => { this._router.navigate(["home"]); });
  }

  createForm() {
    this.filterForm = this.fb.group({
      requestedFrom: [''],
      requestedTo: [''],
      areaOfKnowledge: [[]],
      approvedFrom: [''],
      approvedTo: [''],
      status: [[]],
      approvalCode: ['']
    });
  }

  ngAfterViewInit(): void {
    this.setDatatable();
  }

  setDatatable() {
    let _slThis = this;
    let icId = localStorage.getItem('ic_id');
    this.datatable = $('#example').DataTable({
      info: true,
      language: {
        infoFiltered: ""
      },
      select: {
        style: 'single',
        items: 'cell'
      },
      searching: false,
      responsive: true,
      serverSide: true,
      ajax: {
        url: `${environment.baseUrl}/api/v1/course-activity/dt-datasource`,
        type: 'GET',
        headers: { 'Authorization': ('bearer ' + this._apiConfig.accessToken) },
        data: function (d: any) {
          // Convert the bracket notation to dot notation
          let params = {};

          // Iterate over the columns array and reformat the parameters
          d.columns.forEach((col: any, index: any) => {
            params[`columns[${index}].data`] = col.data;
            params[`columns[${index}].name`] = col.name;
            params[`columns[${index}].searchable`] = col.searchable;
            params[`columns[${index}].orderable`] = col.orderable;
            params[`columns[${index}].search.value`] = col.search.value;
            params[`columns[${index}].search.regex`] = col.search.regex;
          });

          // Add other necessary parameters
          params['draw'] = d.draw;
          params['order[0].column'] = d.order[0].column;
          params['order[0].dir'] = d.order[0].dir;
          params['start'] = d.start;
          params['length'] = d.length;
          params['search.value'] = d.search.value;
          params['search.regex'] = d.search.regex;

          // Add filter parameters
          params["customParams[0]"] = `${icId}`;
          params["customParams[1]"] = `${_slThis.filterForm.value.requestedFrom}`;
          params["customParams[2]"] = `${_slThis.filterForm.value.requestedTo}`;
          params["customParams[3]"] = `${_slThis.filterForm.value.areaOfKnowledge.map((u: CategoryModel) => u.categoryCode).join(', ')}`;
          params["customParams[4]"] = `${_slThis.filterForm.value.approvedFrom}`;
          params["customParams[5]"] = `${_slThis.filterForm.value.approvedTo}`;
          params["customParams[6]"] = `${_slThis.filterForm.value.status}`;
          params["customParams[7]"] = `${_slThis.filterForm.value.approvalCode}`;

          return params;
        }
      },
      // createdRow: (row: Node, data: any[] | object, index: number) => {
      //   // $(row).css('text-align', 'center');
      // },
      columns: [
        {
          className: 'custom-header',
          data: 'individualCode',
          orderable: true,
          searchable: false,
          width: '15%',
          render: (data: any, type: any, row: any) => {
            return '<div style="text-align:center;"><span>' + data + '</span></div>';
          }
        },
        {
          className: 'custom-header',
          data: 'topic',
          orderable: true,
          searchable: false,
          width: '40%',
          render: (data: any, type: any, row: any) => {
            return '<div style="text-align:start;"><span>' + data + '</span></div>';
          }
        }
        , {
          className: 'custom-header',
          data: 'createDate',
          orderable: true,
          searchable: false,
          width: '15%',
          render: (data: any, type: any, row: any) => {
            if (row.createDate != null) {
              let result = this._datePipe.transform(new Date(row.createDate), 'dd/MM/yyyy HH:mm');
              return '<div style="text-align:center;"><span>' + result + '</span></div>';
            } else {
              return '';
            }
          }
        }, {
          className: 'custom-header',
          data: 'tsiApproveDate',
          orderable: true,
          searchable: false,
          width: '15%',
          render: (data: any, type: any, row: any) => {
            if (row.tsiApproveDate != null) {
              let result = this._datePipe.transform(new Date(row.tsiApproveDate), 'dd/MM/yyyy HH:mm');
              return '<div style="text-align:center;"><span>' + result + '</span></div>';
            } else {
              return '';
            }
          }
        }, {
          className: 'custom-header',
          data: 'tsiCasStatusFlow.statusFlow',
          orderable: true,
          searchable: false,
          width: '15%',
          render: (data: any, type: any, row: any) => {
            let text = this.convertToCapitalizationCase(data);
            return '<div style="text-align:center;"><span>' + text + '</span></div>';
          }
        }
      ],
      "dom": '<"top d-flex justify-content-end align-items-center"Blf>rt<"bottom d-flex justify-content-between"ip><"clear">',
      buttons: [
        {
          extend: 'excelHtml5',
          text: 'Export',
          className: `button-display-none ${_slThis.exportButtonClassId}`,
          exportOptions: {
            modifier: {
              page: 'All'
            },
            stripNewlines: false,
            orthogonal: 'export'
          },
        },
        {
          extend: 'csv',
          text: '<i class="far fa-file-excel"></i>',
          className: `btn export-button`,
          action: function (e, dt, button, config) {
            // Add code to make changes to table here
            let currentPageLength = _slThis.datatable.settings().page.len();
            _slThis.datatable.settings().page.len(-1);
            _slThis.drawCallbackBlock = function () {
              $(`.${_slThis.exportButtonClassId}`).click();

              // Set page config back to original value
              setTimeout(() => {
                _slThis.drawCallbackBlock = null;
                _slThis.datatable.settings().page.len(currentPageLength);
                _slThis.datatable.draw();
              }, 200);
            }
            _slThis.datatable.draw();
          }
        }
      ],
      drawCallback() {
        _slThis.drawCallback()
      },
      lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
      order: [[2, 'desc']],
      rowCallback: (row: Node, data: any[] | object, index: number) => {
        $(row).on('click', () => this.onViewCourseActivity(data['individualId']));
      }

    });


  }

  drawCallback() {
    if (this.drawCallbackBlock != null) {
      this.drawCallbackBlock();
    }
  }

  onViewCourseActivity(id: number) {
    this._router.navigate(["course-activity/create", id]);
  }

  onCreateCourse() {
    this._router.navigate(["course-activity/create", 0]);
  }

  onReset() {
    this.filterForm.reset({
      requestedFrom: '',
      requestedTo: '',
      areaOfKnowledge: [],
      approvedFrom: '',
      approvedTo: '',
      status: [],
      approvalCode: ''
    });

    // Reset the selected statuses
    this.selectedStatuses = [];
    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    // Reset the selected categories
    this.selectedCategories = [];

    this.onFilterChanged();
  }

  onRequestedFilterChanged(event: any, type: string) {
    if (type === 'from') {
      this.filterForm.get('requestedFrom').setValue(event.target.value);
      this.onFilterChanged();
    } else if (type === 'to') {
      this.filterForm.get('requestedTo').setValue(event.target.value);
    }
    this.onFilterChanged();
  }

  onItemSelect(item: any) {
    this.selectedCategories.push(item);
    this.filterForm.get('areaOfKnowledge').setValue(this.selectedCategories);
    this.onFilterChanged();
  }

  onItemDeSelect(item: any) {
    // Find the index of the item that should be removed
    const index = this.selectedCategories.findIndex(element => element.categoryCode === item.categoryCode);
    if (index !== -1) {
      this.selectedCategories.splice(index, 1); // Remove the item if found
    }
    this.filterForm.get('areaOfKnowledge').setValue(this.selectedCategories);
    this.onFilterChanged();
  }

  onApprovedFilterChanged(event: any, type: string) {
    if (type === 'from') {
      this.filterForm.get('approvedFrom').setValue(event.target.value);
      this.onFilterChanged();
    } else if (type === 'to') {
      this.filterForm.get('approvedTo').setValue(event.target.value);
    }
    this.onFilterChanged();
  }

  onSelectStatus(event: any) {
    const value = event.target.value;
    if (event.target.checked) {
      // Add the status if it's checked
      this.selectedStatuses.push(value);
    } else {
      // Remove the status if it's unchecked
      const index = this.selectedStatuses.indexOf(value);
      if (index > -1) {
        this.selectedStatuses.splice(index, 1);
      }
    }
    this.filterForm.get('status').setValue(this.selectedStatuses);
    this.onFilterChanged();
  }

  onChangedApprovalCode(event: any): void {
    this.filterForm.get('approvalCode').setValue(event.target.value);
    this.onFilterChanged();
  }

  onFilterChanged() {
    this.datatable.draw();
  }

  convertToCapitalizationCase(text: string): string {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  async getListOfCategory(): Promise<CategoryModel[]> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.getListCategoryUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

}
