<div>
    <div class="text-center">

        <img [src]="profileLink" width="100px" height="100px" onerror="this.src='../../../../../assets/image/unknown_person.jpeg'">
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_FULLNAME' | translate }}</label>
        <p>{{displayName}}</p>
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_BRITHDAY' | translate}}</label>
        <p>{{profile.birthdayTh}}</p>
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_NATIONALITY' | translate}}</label>
        <p>{{profile.nationality.nationalityTh}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode == 'TH'">
        <label>{{'PROFILE_PAGE.T_CARD' | translate}}</label>
        <p>{{profile.idCard}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode != 'TH'">
        <label>{{'PROFILE_PAGE.T_CARD' | translate}}</label>
        <p>{{profile.T_PASSPORT}}</p>
    </div>
    <button *ngIf="canEdit"  type="button" class="btn btn-block un-submit bg-primary" (click)="edit()" >{{'BUTTON.CHANGE_DATA' | translate}}</button>
</div>
