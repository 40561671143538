<div [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
            <section class="pt-3 px-5 text-center container" style="font-size: 1.2rem;">
                <p>{{'CANCEL_CONSENT_PAGE.T_HEADER' | translate}}</p>
            </section>
            <div class="d-flex flex-column">
                <div class="bloghtml" [innerHTML]="textHtml"></div>
                <div class="p-2 flex-fill text-muted">
                    <div class="form-check">
                        <input class="form-check-input" id="confrimCheck" type="checkbox" value=""
                            [(ngModel)]="confrimCheck" [disabled]="!haveCancelConsent">
                        <label class="form-check-label" for="confrimCheck">
                            {{'CANCEL_CONSENT_PAGE.T_NOTE' | translate}}
                        </label>
                    </div>
                </div>
                <div class="pt-3 px-3 pb-0 flex-fill">
                    <p> {{'CANCEL_CONSENT_PAGE.CONTACT_1' | translate}} <a
                            href="https://www.set.or.th/set/contactcenter.do">{{'CANCEL_CONSENT_PAGE.CONTACT_2' |
                            translate}}</a>
                        {{'CANCEL_CONSENT_PAGE.CONTACT_3' | translate}}</p>
                </div>
                <div class="d-flex justify-content-around">
                    <div class="p-2 flex-fill">
                        <button type="button" class="btn btn-block submit bg-primary"
                            (click)="cancel()">{{'BUTTON.CANCEL' | translate}}</button>
                    </div>
                    <div class="p-2 flex-fill">
                        <button type="button" class="btn btn-block un-submit bg-primary" (click)="confirm()"
                            [disabled]="!confrimCheck">{{'BUTTON.CONFIRM' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>