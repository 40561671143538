<div class="card card-body">
    <div>
        <h2 style="color: orange;text-align: center;">Your Prof. Link
            Profile has been Created.</h2>
    </div>
    <div class="card card-body">
        <p>The process of approval may take 15 working days. When approved, we will send the account information to your
            mobile ({{mobileNumber}}).</p>
    </div>
    <div class="form-group pt-3 pb-3">
        <button type="button" class="btn btn-block btn-primary btn-next" style="color: white;" (click)="onClosed()">
            Close
        </button>
    </div>

</div>