import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { flyInAnimation } from '@shared/animations/animation';
import { IcSectionAttendantControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icSectionAttendantController.service';
import * as WSCall from '@app/utils/WSCall';
import { RequestQrCodeModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/requestQrCodeModel';
import { APIResultstring } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import * as Utils from "@app/utils/Utils";
import { APIResultFileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultFileStoreModel';
import { FileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import { FileUploadComponent } from '@app/public/file-upload/file-upload.component';
import { HttpClient } from '@angular/common/http';
import { ProtectedMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import { element } from 'protractor';

import { FileUploadService } from '@app/service/file-service';
import { Router } from '@angular/router';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import { CompleteUploadRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/completeUploadRequest';
import { CheckinOrCheckoutOnlineRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/checkinOrCheckoutOnlineRequest';
import { CheckinCheckoutOnlineResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/checkinCheckoutOnlineResult';
import { DatePipe } from '@angular/common';
import { BrowserQRCodeReader } from '@zxing/browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkin-checkout-online',
  templateUrl: './checkin-checkout-online.component.html',
  styleUrls: ['./checkin-checkout-online.component.scss'],
  animations: [flyInAnimation]
})
export class CheckinCheckoutOnlineComponent implements OnInit {

  scanResult: string = '';

  title = 'Check-in / Out';

  takePhoto: boolean = true;
  completeResult: boolean = false;
  imageUploadComplete: FileStoreModel = null;
  checkInCheckoutState: CheckInCheckoutState = CheckInCheckoutState.SCAN_QR_CODE;
  qrCodeResult: string;

  checkinCheckoutOnlineResult: CheckinCheckoutOnlineResult;
  resultCheckInOutStatus: string;




  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;


  @ViewChild('checkinImageDownload')
  checkinImageDownload: FileUploadComponent;

  @ViewChild('imagePreviewUpload')
  imagePreviewUpload: ElementRef;

  @ViewChild('imageResult')
  imageResult: ElementRef;


  constructor(
    private _icSectionAttendantControllerService: IcSectionAttendantControllerService,
    protected _httpClient: HttpClient,
    private _datePipe: DatePipe,
    private _router: Router,
    private _publicMiscControllerService: PublicMiscControllerService,
    private _protMiscService: ProtectedMiscControllerService,
    private _fileUploadService: FileUploadService,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }


  tilte = "Test scan qr code"

  ngOnInit() {


  }

  async changeFile(event: any) {
    let file: File = event.target.files[0];

    const img: HTMLImageElement = <HTMLImageElement>document.getElementById('imgQrPreview');
    const input: HTMLInputElement = <HTMLInputElement>document.getElementById('inputQrCode');
    let codeReader: BrowserQRCodeReader = new BrowserQRCodeReader();

    if (file) {
      img.src = URL.createObjectURL(file);
      try {
        const result = await codeReader.decodeFromImageElement(img);
        this.onCodeResult(result.getText());
      } catch (err) {
        let msg = 'ไม่สามารถอ่าน QR Code กรุณาเลือกรูปใหม่';
        this._translate.get('UTILS.READ_QR_CODE').subscribe((res: string) => {
          msg = res;
        });
        Utils.error(msg);
      }

    }
    input.value = null;
    // img.src = null;
  }


  async onCodeResult(qrCode: string) {

    this.scanResult = qrCode;
    this.scanner.scanStop();

    let requestQrCodeModel: RequestQrCodeModel = {
      qrCode: qrCode
    };

    this.qrCodeResult = qrCode;

    let resultValidate = await this.validateQrCodeNewUsing(requestQrCodeModel);
    if (resultValidate.resultCode == "SUCCESS") {

      this.checkInCheckoutState = CheckInCheckoutState.UPLOAD_IMAGE;

    } else {

      Utils.error(resultValidate.errorMessage);
      this.scanner.scanStart();

    }
  }

  async nextToResult() {


    let checkinOrCheckoutOnlineRequest: CheckinOrCheckoutOnlineRequest = {
      qrCode: this.qrCodeResult,
      attachedFile: this.imageUploadComplete
    };

    let checkinCheckoutOnlineResult: CheckinCheckoutOnlineResult = await this.checkinOrCheckoutOnline(checkinOrCheckoutOnlineRequest);
    this.checkinCheckoutOnlineResult = checkinCheckoutOnlineResult;
    if (checkinCheckoutOnlineResult.checkedStatus) {
      let mag = 'ดำเนินการสำเร็จ';
      this._translate.get('DIGITAL_PASSPORT_PAGE.ACTION_SUCCESS').subscribe((res: string) => {
        mag = res;
      });
      'ดำเนินการสำเร็จ';
    } else {
      let mag = 'ดำเนินการไม่สำเร็จ';
      this._translate.get('DIGITAL_PASSPORT_PAGE.ACTION_FAIL').subscribe((res: string) => {
        mag = res;
      });
      this.resultCheckInOutStatus = 'ดำเนินการไม่สำเร็จ';
    }
    console.log(checkinCheckoutOnlineResult);

    this.checkInCheckoutState = CheckInCheckoutState.RESULT;
  }

  nextToDigitalPassport() {
    this._router.navigate(["profile/digital-passport"]);
  }

  async getDownloadLink(): Promise<FileStoreModel> {
    let fileStoreUploadFile: FileStoreModel = await this.getUploadLinkForCheckinImage();
    // let urlDownload:string = fileStoreUploadFile.attachmentLink;

    const promise = new Promise<FileStoreModel>(function (resolve, reject) {
      resolve(fileStoreUploadFile);
    });

    return promise;
  }

  async getUploadLinkForCheckinImage(): Promise<FileStoreModel> {

    return WSCall.wsCall(
      () => {
        return this._icSectionAttendantControllerService.getUploadLinkForCheckinImageUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }


  async checkinOrCheckoutOnline(checkinOrCheckoutOnlineRequest: CheckinOrCheckoutOnlineRequest): Promise<CheckinCheckoutOnlineResult> {

    return WSCall.wsCall(
      () => {
        return this._icSectionAttendantControllerService.checkinOrCheckoutOnlineUsingPOST(checkinOrCheckoutOnlineRequest);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async validateQrCodeNewUsing(requestQrCodeModel: RequestQrCodeModel): Promise<APIResultstring> {

    return WSCall.wsCall(
      () => {
        return this._icSectionAttendantControllerService.validateQrCodeNewUsingPOST(requestQrCodeModel);
      },
      respBody => {
        return respBody;
      }
    );
  }


  async onImageChange(file: any) {
    if (file != null) {
      // this.blockUI.start('Loading...');
      let uploadingFs = await this.getDownloadLink();
      let completeUpload: CompleteUploadRequest = {

      };

      await this.checkinImageDownload.upload(this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLink, async () => {
        await this._publicMiscControllerService.completeUploadUsingPOST1(uploadingFs.fileId, completeUpload);

      }, () => {
        // On fail
        // this.blockUI.stop();
      });
    }
  }

  async onFileSelected(event: any) {

    let file: File = event.target.files[0];
    let uploadingFs = await this.getDownloadLink();
    let completeUpload: CompleteUploadRequest = {

    };

    await this._fileUploadService.uploadWithFile(file, this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLink, async () => {
      this.imagePreviewUpload.nativeElement.src = uploadingFs.downloadLink;
      this.imageUploadComplete = uploadingFs;
      this.completeResult = true;

      await this._publicMiscControllerService.completeUploadUsingPOST1(uploadingFs.fileId, completeUpload);

    }, () => {
      // On fail
      // this.blockUI.stop();
    });

  }

  displayDate(date: Date) {
    let language = localStorage.getItem('language');

    let dateMonth = this._datePipe.transform(date, 'dd MMM', null, language == 'th' ? null : 'en');
    let year = Number(this._datePipe.transform(date, 'yyyy'));
    year = language == 'th' ? year + 543 : year;
    let displayDate = dateMonth + ' ' + year;



    return displayDate;
  }


  displayBetweenCheckinCheckout(checkinCheckoutOnline: CheckinCheckoutOnlineResult) {


    let displayStartDate = this.displayTimeWithMinute(checkinCheckoutOnline.sectionStartTime.valueOf());
    let displayEndDate = this.displayTimeWithMinute(checkinCheckoutOnline.sectionEndTime.valueOf());

    let displayReturn = displayStartDate + " - " + displayEndDate;


    return displayReturn;
  }



  displayTimeWithDate(date: Date) {


    let dateTime = this._datePipe.transform(date, 'HH:mm');

    return dateTime;
  }

  displayTimeSecWithDate(date: Date) {

    let dateTime = this._datePipe.transform(date, 'HH:mm:ss');

    return dateTime;

  }


  displayTimeWithMinute(minute: number) {

    let date: Date = new Date(0, 0, 0, 0, minute, 0,);
    let dateTime = this._datePipe.transform(date, 'HH:mm');

    return dateTime;

  }

  displayTimeSecWithMinute(minute: number) {

    let date: Date = new Date(0, 0, 0, 0, minute, 0,);

    let dateTime = this._datePipe.transform(date, 'HH:mm:ss');

    return dateTime;

  }

}


export type CheckInCheckoutState = 'SCAN_QR_CODE' | 'UPLOAD_IMAGE' | 'RESULT'
export const CheckInCheckoutState = {

  SCAN_QR_CODE: 'SCAN_QR_CODE' as CheckInCheckoutState,
  UPLOAD_IMAGE: 'UPLOAD_IMAGE' as CheckInCheckoutState,
  RESULT: 'RESULT' as CheckInCheckoutState

}
