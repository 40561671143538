<div [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
            <section class="pt-3 px-5 text-center container" style="font-size: 1.2rem;">
                <p>{{'TERM_AND_CONDITION.T_HEADER_1' | translate}}</p>
                <p>{{'TERM_AND_CONDITION.T_HEADER_2' | translate}}{{'TERM_AND_CONDITION.T_HEADER_3' | translate}}</p>
            </section>

            <div class="d-flex flex-column">
                <!-- <div class="embed-responsive embed-responsive-4by3"> -->
                <div class="bloghtml" [innerHTML]="textHtml"></div>
                <!-- </div> -->
                <div class="p-2 flex-fill">
                    <label class="p-0">{{'TERM_AND_CONDITION.T_DATE' | translate}}</label>
                    <label class="p-1">{{agreeConsentDateDisplay}}</label>
                </div>
                <div class="p-2 flex-fill">
                    <a href="https://www.set.or.th/th/privacy-notice"
                        target="_blank">{{'TERM_AND_CONDITION.T_PROTECTION_1' |
                        translate}}
                        <div>
                            <ul>
                                <li>{{'TERM_AND_CONDITION.T_PROTECTION_2' |
                                    translate}}</li>
                                <li>{{'TERM_AND_CONDITION.T_PROTECTION_3' |
                                    translate}}</li>
                                <li>{{'TERM_AND_CONDITION.T_PROTECTION_4' |
                                    translate}}</li>
                            </ul>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>