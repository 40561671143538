<div class="card card-body">
    <label>Verify Mobile Number</label>
    <div class="card card-body">
        <p>We’ve sent an OTP to mobile number, please enter the OTP to complete your profile.</p>
        <div class="mobile-info">
            <div>
                <strong>Mobile Number</strong>
                <span>{{ mobileNumber }}</span>
            </div>
            <div>
                <strong>Code</strong>
                <span>{{ refCode }}</span>
            </div>
        </div>
        <form>
            <strong for="otp">OTP from mobile</strong>
            <input type="text" id="inputOtp" [(ngModel)]="inputOtp" name="inputOtp" required>
            <div class="otp-timer">
                <span>OTP expire in {{counter | formatTime}} mins</span>
            </div>
            <button type=" button" class="btn btn-secondary" [disabled]="!canRequestOtp"
                style="width: 100%; background-color: #D9D9D9; border-width: 0;" (click)="requestOtp()">
                Resend OTP
            </button>
        </form>
    </div>

    <div class="form-group pt-3 pb-3">
        <button type="button" class="btn btn-secondary" style="width: 100%; background-color: #D9D9D9; border-width: 0;"
            (click)="onBack()">
            Back
        </button>
        <button type="button" [disabled]="!inputOtp" class="btn btn-secondary"
            style="width: 100%; background-color: #03A439; border-width: 0; color: white;" (click)="submitOtp()">
            Verify
        </button>
    </div>
</div>