<div class="px-3 py-2">

    <div class="card" *ngIf="registerSession != null && getLinkDownload(registerSession) != null">
        <h5 class="card-title pl-3 pt-3">{{'MY_COURSE.TITLE_CERTIFICATE' | translate}}</h5>
        <div class="card-body">
            <div class="d-flex flex-fill justify-content-center">
                <a [href]="getLinkDownload(registerSession)" target="_blank">
                    <img width="75px" height="75px" src="../../../../assets/image/ic_file_type_pdf.png" />
                
                </a>   
            </div> <div class="d-flex flex-fill justify-content-center">{{registerSession.certificate.fileName}}</div>
        </div>
    </div>
        <div class="container p-4" *ngIf="quizsatData.length>0">
            <div class="p-1" *ngFor="let i of quizsatData">
            <button *ngIf="i.type=='1' && i.active" type="button" class="btn btn-block btn-success" (click)="beginRedirectUrl(i.quizFormId)">
                <label style="color: white;">{{'MY_COURSE.FORM_SATISFACTION' | translate}}</label>
            </button>
            <button *ngIf="i.type=='3' && i.active" type="button" class="btn btn-block btn-info" (click)="beginPrePostTest(i.quizFormId,i.entryCode)">
                <label style="color: white;">{{'MY_COURSE.FORM_PRE' | translate}}</label>
            </button>
            <button *ngIf="i.type=='4' && i.active" type="button" class="btn btn-block btn-info" (click)="beginPrePostTest(i.quizFormId,i.entryCode)">
                <label style="color: white;">{{'MY_COURSE.FORM_POST' | translate}}</label>
            </button>
        </div>
        </div>
  
    <div class="card">
        <h5 class="card-title pl-3 pt-3">{{'MY_COURSE.ATTENDANE_RECORD' | translate}}</h5>
        <div class="card-body">

            <div class="card m-1" *ngFor="let atPc of attendantPerCourses">
                <h5 class="card-title p-2 text-title">{{atPc.courseCode}} : {{atPc.courseName}}</h5>
                <div class="card-body">

                    <div class="flex-row d-flex">
                        <div class="pr-2 pt-2 text-warning">
                            {{getPercentTotalLearning(atPc)}} %
                        </div>
                        <div class="flex-row d-flex flex-fill">
                            <div class="p-2">
                                0%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                25%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                50%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                75%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                100%
                            </div>
                        </div>
                    </div>
                    <div class="flex-row d-flex">
                        <div class="pr-2">
                            {{atPc.totalCourseMinutes/60}} {{'PUBLISH.HOUR_HR' | translate}}
                        </div>
                        <div class="progress flex-fill m-0">
                            <div class="progress-bar bg-success bg-bar-first" [style]="'width:'+getPercentTotalLearning(atPc)+'%'">
                            </div>
                            <div class="progress-bar bg-secondary bg-bar-secondary"
                                [style]="'width:'+getPercentTotalCourseProgress(atPc)+'%'"></div>
                        </div>
                    </div>
                    <div>
                        <div class="result-score">
                            <p>Pre-test
                                <br/><span>
                                Max.  {{mapPreTestMax.get(atPc.courseCode) == null?"-":mapPreTestMax.get(atPc.courseCode)}}
                                    Min.  {{mapPreTestMin.get(atPc.courseCode) == null?"-":mapPreTestMin.get(atPc.courseCode)}}
                                    Avg. {{mapPreTestAvg.get(atPc.courseCode)== null?"-":mapPreTestAvg.get(atPc.courseCode)}}
                                    S.D. {{mapPreTestSd.get(atPc.courseCode) == null?"-":mapPreTestSd.get(atPc.courseCode)}}
                                </span>
                            </p>
                            <span class="result-score-total">
                                {{mapPreTestScore.get(atPc.courseCode) == null?"-":mapPreTestScore.get(atPc.courseCode)}}/{{mapPreTestFullScore.get(atPc.courseCode)==null?"-":mapPreTestFullScore.get(atPc.courseCode)}}
                            </span>
                        </div>
                        <div class="result-score">
                            <p>Post-test
                                <br/><span>
                                Max.  {{mapPostTestMax.get(atPc.courseCode) == null?"-":mapPostTestMax.get(atPc.courseCode)}}
                                Min.  {{mapPostTestMin.get(atPc.courseCode) == null?"-":mapPostTestMin.get(atPc.courseCode)}}
                                Avg. {{mapPostTestAvg.get(atPc.courseCode)== null?"-":mapPostTestAvg.get(atPc.courseCode)}}
                                S.D. {{mapPostTestSd.get(atPc.courseCode) == null?"-":mapPostTestSd.get(atPc.courseCode)}}</span></p>
                            <span class="result-score-total">
                                {{mapPostTestScore.get(atPc.courseCode) == null?"-":mapPostTestScore.get(atPc.courseCode)}}/{{mapPostTestFullScore.get(atPc.courseCode)==null?"-":mapPostTestFullScore.get(atPc.courseCode)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card m-1">
                <h5 class="card-title p-2">Total</h5>
                <div class="card-body">

                    <div class="flex-row d-flex">
                        <div class="pr-2 pt-2 text-info">
                            {{percentTotalLearning}} %
                        </div>
                        <div class="flex-row d-flex flex-fill">
                            <div class="p-2">
                                0%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                25%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                50%
                            </div>
                            <div class="p-2 flex-fill text-right">
                                75%
                            </div>

                            <div class="p-2 flex-fill text-right">
                                100%
                            </div>
                        </div>
                    </div>

                    <div class="flex-row d-flex">
                        <div class="pr-2">
                            {{totalCourseMinutes/60}} {{'PUBLISH.HOUR_HR' | translate}}
                        </div>
                        <div class="progress flex-fill m-0">
                            <div class="progress-bar bg-secondary" [style]="'width:'+percentTotalCourseProgress+'%'">
                            </div>
                            <div class="progress-bar bg-info bg-bar-first bg-bar-info" [style]="'width:'+percentTotalLearning+'%'"></div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-2" *ngIf="session!= null">
        <h5 class="card-title pl-3 pt-3">{{'MY_COURSE.TITLE_TIME' | translate}}</h5>
        <div *ngFor="let td of session.trainingDates;index as i" class="card-body">
            <div class="card m-1">
                <div (click)="collapse.toggle()" class="card-header flex-row d-flex">
                    <div class="p-0 flex-fill">
                        <a [attr.aria-expanded]="!arrayCollapses[i]" aria-controls="collapseExample">
                            {{getDateDisplay(td.trainingDate)}}
                        </a>
                    </div>

                    <div class="p-0">
                        <i class="fa fa-angle-down"></i>
                    </div>
                </div>

                <div #collapse="ngbCollapse" [(ngbCollapse)]="arrayCollapses[i]">
                    <div *ngFor="let sd of td.sections" class="card">

                        <div *ngFor="let sc of sd.sectionPeriod">
                            <div class="card-body m-0 p-0">
                                <div class="d-flex flex-row flex-fill">
                                    <div class="pl-2 pt-2">
                                        <label class="title-checkin-out">Check-in</label>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="p-2 flex-fill">{{getDateTime(sc.actualStartTime)}}</div>
                                    <div class="p-2 flex-fill">
                                        {{getCheckInOutTime(td.trainingDate,'checkin',sc.actualStartTime,sc.actualEndTime)[0]}}
                                    </div>
                                    <div class="p-2 flex-fill">
                                        {{getCheckInOutTime(td.trainingDate,'checkin',sc.actualStartTime,sc.actualEndTime)[1]}}
                                    </div>
                                </div>
                                <div class="d-flex flex-row flex-fill">
                                    <div class="pl-2 pt-2">
                                        <label class="title-checkin-out">Check-out</label>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="p-2 flex-fill">{{getDateTime(sc.actualEndTime)}}</div>
                                    <div class="p-2 flex-fill">
                                        {{getCheckInOutTime(td.trainingDate,'checkout',sc.actualStartTime,sc.actualEndTime)[0]}}
                                    </div>
                                    <div class="p-2 flex-fill">
                                        {{getCheckInOutTime(td.trainingDate,'checkout',sc.actualStartTime,sc.actualEndTime)[1]}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
