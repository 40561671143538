
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as WSCall from '@app/utils/WSCall';
import { IcAgreeConsentLogControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icAgreeConsentLogController.service';
import { IcAgreeConsentLogModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogModel';
import { ConsentVersionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service';
import { ConsentVersionPublicControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionPublicController.service';
import { ConsentVersionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/consentVersionModel';
import { DatePipe } from '@angular/common';
import { flyInAnimation } from '@app/shared/animations/animation';
import { b64toBlob } from '@app/utils/Utils';
import { TranslateService } from '@ngx-translate/core';
import { lang } from 'moment';

@Component({
  selector: 'app-condition-and-agreement',
  templateUrl: './condition-and-agreement.component.html',
  styleUrls: ['./condition-and-agreement.component.scss'],
  animations: [flyInAnimation]
})
export class ConditionAndAgreementComponent implements OnInit {
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: { pdfSrc: Blob; refresh: () => void; };
  title = '';

  constructor(
    private sanitizer: DomSanitizer,
    private _icAgreeConsentLogControllerService: IcAgreeConsentLogControllerService,
    private _consentVersionPublicControllerService: ConsentVersionPublicControllerService,
    private _consentVersionControllerService: ConsentVersionControllerService,
    private _datePipe: DatePipe,
    private _translate: TranslateService

  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  pdfUrl: SafeResourceUrl;
  agreeConsentDateDisplay = '';
  textHtml: string = "";

  async ngOnInit(): Promise<void> {
    this._translate.get('MENU_BAR.TERM_AND_CONTITION').subscribe((res: string) => {
      this.title = res;
    });
    this.loadConsentLatest();
    // await this.loadPdf();
  }

  async loadConsentLatest() {
    let consentVersionModel: ConsentVersionModel = await this.getLatestConsentVersionAppDescription();
    let icAgreeConsentLogModel: IcAgreeConsentLogModel = await this.getLatestByConsentType(consentVersionModel.consentType.typeName);
    this.agreeConsentDateDisplay = this.getDisplayDate(icAgreeConsentLogModel.createDate);
    let consentHtml: string = await this.getLatestConsentVersionAppHtml();
    this.textHtml = consentHtml;

    // this._consentVersionControllerService.getLatestByTypeConsentVersionBase64PDFUsingGET(consentVersionModel.consentType.typeName).subscribe(data => {
    //   this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
    //   this.pdfViewerAutoLoad.refresh();
    // });
  }

  // async loadPdf() {
  //   let consentPdf: string = await this.getLatestConsentVersionAppBase64PDF();
  //   this.pdfUrl = this.toSecureURLPdfStr(consentPdf);
  //   console.log(this.pdfUrl);
  // }

  // toSecureURLPdfStr(data: string) {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(
  //     'data:application/pdf;base64,' + data
  //   );
  // }

  async getLatestConsentVersionAppDescription(): Promise<ConsentVersionModel> {
    return WSCall.wsCall(
      () => {
        return this._consentVersionPublicControllerService.getLatestConsentVersionAppDescriptionUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getLatestByConsentType(contentType: string): Promise<IcAgreeConsentLogModel> {
    return WSCall.wsCall(
      () => {
        return this._icAgreeConsentLogControllerService.getLatestByConsentTypeUsingGET(contentType);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getLatestConsentVersionAppBase64PDF(): Promise<string> {
    return WSCall.wsCall(
      () => {
        return this._consentVersionPublicControllerService.getLatestConsentVersionAppBase64PDFUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getLatestConsentVersionAppHtml(): Promise<string> {
    return WSCall.wsCall(
      () => {
        return this._consentVersionPublicControllerService.getLatestConsentVersionAppHtmlUsingGET('SEC_CHECK_IC_FOR_IC');
      },
      respBody => {
        return respBody.data;
      }
    );
  }



  getDisplayDate(date: Date) {
    let language = localStorage.getItem('language');

    if (date == null) {
      return null;
    }

    let dateMonthConsent = this._datePipe.transform(date, 'dd MMMM', null, language == 'th' ? null : 'en');
    let yearConsernt = Number(this._datePipe.transform(date, 'yyyy'));
    let timeConsent = this._datePipe.transform(date, 'HH:mm');
    yearConsernt = language == 'th' ? yearConsernt + 543 : yearConsernt;

    let displayDate = dateMonthConsent + ' ' + yearConsernt + ' ' + timeConsent;

    return displayDate;

  }

}
