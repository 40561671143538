<app-header-with-back class="header" [title]="noticeTypeName" [path]="'notification'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div class="text-center {{getColorNotice(noticeTypeId)}} text-white py-3">
            <div class="mt-2 title-topic">{{title}}</div>
        </div>
        <div class="p-3">
            <div class="pt-1 pr-3 flex-fill text-center" *ngIf="imageNews">
                <img class="image-notification" [src]="imageNews"
                    onerror="this.src='../../../../../assets/image/bg-gray.png'">
            </div>
            <div class="p-2 card-text">
                {{detail}}
            </div>
        </div>

        <footer class="m-4 fixed-bottom text-center">
            <!-- Grid container -->
            <!-- <button *ngIf="actionType===1" type="button" class="px-5 btn btn-primary"
                (click)="redirectToNewsAndActivity()">ดูรายละเอียด
            </button> -->
            <button *ngIf="actionType===2" [disabled]="!isFormActive" type="button" class="px-5 btn btn-primary"
                (click)="beginPrePostTest()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_START_TEST' | translate}}</button>
            <button *ngIf="actionType===3" [disabled]="!isFormActive" type="button" class="px-5 btn btn-primary"
                (click)="beginRedirectUrl()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_START_SATISFACTION' | translate}}
            </button>
            <button *ngIf="actionType===4" type="button" class="px-5 btn btn-primary"
                (click)="redirectToSessionDetail()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===6" type="button" class="px-5 btn btn-primary"
                (click)="redirectToLicenseStatus()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===7&&noticeTypeId==6" type="button" class="px-5 btn btn-primary"
                (click)="redirectNotiExtension()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===7&&noticeTypeId==3" type="button" class="px-5 btn btn-primary"
                (click)="redirectToLicenseRegulationDetail()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===8" type="button" class="px-5 btn btn-primary"
                (click)="redirectToICProfile()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===9" type="button" class="px-5 btn btn-primary"
                (click)="redirectToDigitalPassport()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <button *ngIf="actionType===10" type="button" class="px-5 btn btn-primary"
                (click)="redirectToNewsAndActivity()">{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' |
                translate}}
            </button>
            <!-- Grid container -->
        </footer>
    </div>
</div>