import { Component, OnInit } from '@angular/core';
import { AppState } from '@core/app-state';
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { APIResultboolean } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean";
import * as WSCall from "@app/utils/WSCall";
import { APIResultMapstringstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultMapstringstring";
import { AuthProvider } from "@core/auth/auth-provider.service";
import { sha256 } from 'js-sha256';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import toastr = require('toastr');
import { ConsentVersionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { ForceUpdateResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/forceUpdateResponse';
import { Configuration, PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _router: Router,
    private _consentVersionControllerService: ConsentVersionControllerService,
    private _accountControllerService: AccountControllerService,
    private _activatedRoute: ActivatedRoute,
    private _apiConfig: Configuration,
    private _publicMiscControllerService: PublicMiscControllerService,
    private _modalService: NgbModal,
    private _translate: TranslateService) { }

  forceUpdateEmail: boolean = false;
  icProfile: IcProfileModel = null;
  language: string = 'th';

  async ngOnInit() {
    this.language = localStorage.getItem('language');
    this._translate.use(this.language);

    await this.loadForceUpdateEmail();
    this._activatedRoute.queryParams.subscribe(async params => {

      let refreshToken = params['refreshToken'];
      let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
      if (refreshToken != null && refreshToken != '') {
        this._appState.loginWithRefreshToken(refreshToken, deviceToken).subscribe(async out => {
          try {
            const accessToken: string = (<any>out).access_token;
            const refreshToken = (<any>out).refresh_token;
            const appUserModel: IcProfileModel = <IcProfileModel>(<any>out).data;
            this._apiConfig.accessToken = accessToken;
            this._appState.setCurrentUser(appUserModel);
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('ic_id', appUserModel.icId.toString())
            localStorage.setItem('refresh_token', refreshToken);
            await this._router.navigate(['home']);

          } catch (e) {
            console.log("can't login with refresh token")
          }

        });
      }
    });


  }

  entryData: any = {
    username: "",
    password: ""
  };
  closeResult = '';

  canRegister = false;
  canForgotPassword = false;
  showFromPassword: boolean = false;
  isForgotPIN = false;
  isForeigner = false;
  registerHeader = "";
  registerDes = "";
  registerButtonText = "";


  async submitLogin() {

    if (!this.showFromPassword) {
      await this.validateUserName();
    } else {
      await this.login();
    }

  }

  async validateUserName() {
    if (this.entryData.username == null || this.entryData.username.trim() === '') {
      document.getElementById('usernameError').textContent = 'Please enter your username.';
      return
    } else {
      document.getElementById('usernameError').textContent = '';
    }
    let formLogin: RegisterICTemporaryAccountModel = {
      icIdentityNumber: this.entryData.username
    };

    let dataRusult: APIResultMapstringstring = await this.checkUsernameUsingPOST(formLogin);
    let result = dataRusult.data.result;
    if (result == "1") {
      this.showFromPassword = true;
    } else {
      this.canRegister = true;
      if (this.entryData.username.length <= 10) {
        this._translate.use('en');
        this.isForeigner = true;
        localStorage.setItem('type_user', "foreigner");
        localStorage.setItem('language', "en");
      } else {
        this._translate.use('th');
        this.isForeigner = false;
        localStorage.setItem('type_user', "thai");
        localStorage.setItem('language', "th");
      }
      //wait for implement
    }

  }

  async login() {
    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    let hashDataP = sha256("" + this.entryData.password);
    let username = this.entryData.username;
    this._appState.login(username, hashDataP, deviceToken)

      .then(async data => {

        let canRedirect: boolean = await this.validateOpenForceUpdateEmail(data);
        if (!canRedirect) {
          return;
        }
        let resultCheckConsent = await this.checkNewConsentApp();

        if (resultCheckConsent.data) {
          this._router.navigate(["login-consent"]);
        } else {
          this._router.navigate(["home"]);
        }

      })
      .catch(reason => {
        if (reason == "2") {
          this._appState.tempPassword = hashDataP;
          this._appState.tempUser = username;
          this._router.navigate(["otp"]);
        } else {
          toastr.error(reason);
        }

      });
  }

  async checkUsernameUsingPOST(formLogin: RegisterICTemporaryAccountModel): Promise<APIResultMapstringstring> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.checkUsernameUsingPOST(formLogin);
      },
      respBody => {
        return respBody;
      }
    );
  }

  forgotPassword() {
    this._modalService.dismissAll();
    if (this.entryData.username.trim().length <= 0) {
      return;
    }
    this._appState.tempUser = this.entryData.username;
    this._router.navigate(["forgot-password"]);
  }

  isEmptyUserName() {
    return this.entryData.username.length <= 0;
  }

  onEnterKeyUserName(evt: KeyboardEvent) {
    if (evt.code == 'Enter') {
      this.submitLogin();
    }

  }

  onEnterKeyPassword(evt: KeyboardEvent) {
    if (evt.code == 'Enter') {
      this.submitLogin();
    }

  }

  onChangeInputUsername() {
    if (!this.isEmptyUserName()) {
      this.canRegister = false;
      this._appState.tempUser = this.entryData.username;
      this.canForgotPassword = true;
    } else {
      this.canForgotPassword = false;
    }
  }

  registerIcProfile() {

    this._router.navigate(['register']);
  }

  forgotPIN() {
    this.isForgotPIN = true;
  }
  changeMobileNumber() {
    this._modalService.dismissAll();
    this._router.navigate(['change-mobile-number']);
  }

  open(content: any) {
    this._modalService.open(content);
  }

  async checkNewConsentApp(): Promise<APIResultboolean> {
    return WSCall.wsCall(
      () => {
        return this._consentVersionControllerService.checkNewConsentAppUsingGET();
      },
      respBody => {
        return respBody;
      }
    );
  }


  async loadForceUpdateEmail() {
    let result: ForceUpdateResponse = await this.getForceUpdateEmail();
    if (result.forceUpdateEmail) {
      this.forceUpdateEmail = result.forceUpdateEmail;
    }

    console.log(this.forceUpdateEmail);
  }

  async validateOpenForceUpdateEmail(icProfile: IcProfileModel) {
    let canRedirect: boolean = true;
    if (this.forceUpdateEmail && icProfile.emailValidated == false) {
      await this._router.navigate(["email-validate"]);
      canRedirect = false;
    }
    return canRedirect;
  }

  async getForceUpdateEmail(): Promise<ForceUpdateResponse> {
    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.getForceUpdateUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }


}
