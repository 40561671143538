<form [formGroup]="form">
    <div class="form-group">

        <div class="custom-input">

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_CARD' | translate}}</label>
            </div>

            <div class="form-group">
                <p>{{profile.idCard}}</p>
                <!-- <input class="form-control" formControlName="idCardNumber" [readOnly]="true"/> -->
            </div>

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_FIRST_NAME' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="firstName"
                    [ngClass]="{ 'is-invalid': form.controls.firstName?.touched && form.controls.firstName.errors?.required}" />
                <span *ngIf="form.controls.firstName?.touched && form.controls.firstName.errors?.required"
                    class="danger">
                    {{'PROFILE_PAGE.REQUIRE_FIRST_NAME' | translate}}
                </span>
            </div>

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_MIDDLE_NAME' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="middleName" />
            </div>

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_LAST_NAME' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="lastName"
                    [ngClass]="{ 'is-invalid': form.controls.lastName?.touched && form.controls.lastName.errors?.required}" />
                <span *ngIf="form.controls.lastName?.touched && form.controls.lastName.errors?.required" class="danger">
                    {{'PROFILE_PAGE.REQUIRE_LAST_NAME' | translate}}
                </span>
            </div>

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_BRITHDAY' | translate}}</label>
            </div>

            <div class="form-group">
                <p>{{profile.birthdayTh}}</p>
                <!-- <input class="form-control" formControlName="birthday" [readOnly]="true"/> -->
            </div>

            <div class="form-group m-0">
                <label>{{'PROFILE_PAGE.T_BACK_CARD' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="laserId"
                    [ngClass]="{ 'is-invalid': form.controls.laserId?.touched && form.controls.laserId.errors?.required}" />
                <span *ngIf="form.controls.laserId?.touched && form.controls.laserId.errors?.required" class="danger">
                    {{'PROFILE_PAGE.REQUIRE_BACK_CARD' | translate}}
                </span>
            </div>

            <div class="form-group">
                <button type="button" class="btn btn-block un-submit bg-primary" (click)="save()">{{'BUTTON.SAVE' |
                    translate}}</button>
            </div>

        </div>
    </div>
</form>