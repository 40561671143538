<div class="language-switcher" [ngClass]="{'disabled': isDisabledLang}">
    <div class="selected-lang" (click)="toggleDropdown()">
        <img [src]="currentLangFlag" alt="Selected Language Flag">
        <span>{{ currentLang | uppercase }}</span>
        <span> &#9662;</span>
    </div>
    <ul class="dropdown" *ngIf="dropdownVisible">
        <li *ngFor="let lang of languages" (click)="switchLanguage(lang.code)">
            <img [src]="lang.flag" alt="{{ lang.name }}">
            <span>{{ lang.name }}</span>
        </li>
    </ul>
</div>