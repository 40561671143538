import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as WSCall from '@app/utils/WSCall';
import { ConsentVersionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service';
import { ConsentVersionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/consentVersionModel';
import { flyInAnimation } from '@app/shared/animations/animation';
import { b64toBlob } from '@app/utils/Utils';
import { Location } from '@angular/common';
import * as Utils from '@app/utils/Utils';
import { ConsentVersionPublicControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancellation-and-privacy',
  templateUrl: './cancellation-and-privacy.component.html',
  styleUrls: ['./cancellation-and-privacy.component.scss'],
  animations: [flyInAnimation]
})
export class CancellationAndPrivacyComponent implements OnInit {
  // @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: { pdfSrc: Blob; refresh: () => void; };

  title = '';
  haveCancelConsent: boolean;
  confrimCheck: boolean;
  textHtml: string = "";

  constructor(
    private sanitizer: DomSanitizer,
    private _router: Router,
    private _location: Location,
    private _consentVersionControllerService: ConsentVersionControllerService,
    private _consentVersionPublicControllerService: ConsentVersionPublicControllerService,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  async ngOnInit() {
    this._translate.get('MENU_BAR.CANCEL_CONSENT').subscribe((text: string) => {
      this.title = text;
    });
    this._consentVersionPublicControllerService.getLatestConsentVersionAppHtmlUsingGET('CANCELED').subscribe(
      data => {
        if (data.resultCode === 'SUCCESS' && data.data != null && data.data != '') {
          this.haveCancelConsent = true;
          this.textHtml = data.data;
        } else {
          console.log(data);
        }
      }, error => {
        console.log(error);
      }, () => console.log('Process Completed'));


    // this._consentVersionControllerService.getLatestCanceledConsentVersionBase64PDFUsingGET().subscribe(
    //   data => {
    //     this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
    //     this.pdfViewerAutoLoad.refresh();
    //     if (data.resultCode === 'SUCCESS') {
    //       this.haveCancelConsent = true;
    //     } else {
    //       console.log(data);
    //     }
    //   }, error => {
    //     console.log(error);
    //   }, () => console.log('Process Completed'));
  }

  cancel(): void {
    this._location.back();
  }

  confirm(): void {
    this._consentVersionControllerService.cancelConsentUsingPOST().subscribe(data => {
      let msg = '';
      if (data.resultCode === 'SUCCESS') {
        this._translate.get('UTILS.CANCEL_CONSENT').subscribe((text: string) => {
          msg = text;
        });
        Utils.success('', msg, '', () => {
          this._router.navigate(['more/privacy-setting']);
        });
      } else {
        this._translate.get('UTILS.TITLE_ERROR').subscribe((text: string) => {
          msg = text;
        });
        Utils.error(data.errorMessage, msg, () => { });
      }
    });
  }
}
