<div>
  <div>
    <label class="text-title">{{'PROFILE_PAGE.T_LICENSE_NUMBER' | translate}}</label>
    <p>{{ profile.icCode }}</p>
  </div>
  <div>
    <label class="text-title">{{'PROFILE_PAGE.T_LICENSE_DATE' | translate}}</label>
    <p>{{ expireDate | dateThai }}</p>
  </div>
  <div>
    <label class="text-title">{{'PROFILE_PAGE.T_LICENSE_CURRENT' | translate}} </label>
    <ul *ngIf="currentLicense.length > 0">
      <li *ngFor="let item of currentLicense">
        {{
          item.licensePrivilegeType != null && item.licensePrivilegeType != ""
            ? item.licenseNameTh +
              ('PROFILE_PAGE.USE_RIGHTS' | translate) +
              item.licensePrivilegeType +
              " "
            : item.licenseNameTh
        }}
        ({{ item.effectiveDate | dateThai }} -
        {{
          item.expiredDate != null
            ? (item.expiredDate | dateThai)
            : ('PROFILE_PAGE.CURRENT' | translate)
        }})
      </li>
    </ul>
    <ul *ngIf="currentLicense.length == 0">
      -
    </ul>
  </div>
  <div>
    {{'PROFILE_PAGE.T_LICENSE_AGREE' | translate}}
    <a
      class="text-title"
      style="cursor: pointer"
      (click)="isLicenseCollapsed = !isLicenseCollapsed"
      [attr.aria-expanded]="isLicenseCollapsed"
      aria-controls="collapseExample"
    >
    {{ isLicenseCollapsed ? ('PROFILE_PAGE.HIDE' | translate) : ('PROFILE_PAGE.SHOW' | translate) }}
    </a>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!isLicenseCollapsed">
      <ul *ngIf="historyLicense.length > 0">
        <li *ngFor="let item of historyLicense">
          {{ item.effectiveDate | dateThai }} -
          {{ item.expiredDate | dateThai }} :
          {{
            item.licensePrivilegeType != null && item.licensePrivilegeType != ""
              ? item.licenseNameTh +
              ('PROFILE_PAGE.USE_RIGHTS' | translate) +
                item.licensePrivilegeType +
                " "
              : item.licenseNameTh
          }}
        </li>
      </ul>
      <ul *ngIf="historyLicense.length == 0">
        -
      </ul>
    </div>
  </div>
  <div>
    <label class="text-title">{{'PROFILE_PAGE.T_WORK_CURRENT' | translate}}</label>
    <ul *ngIf="currentWorking.length > 0">
      <li *ngFor="let item of currentWorking">
        {{ item.secRoleNameTh }} ({{ item.startDate | dateThai }} -
        {{
          item.endDate != null ? (item.endDate | dateThai) : ('PROFILE_PAGE.IS_WORK' | translate)
        }}
        )
      </li>
    </ul>
    <ul *ngIf="currentWorking.length == 0">
      -
    </ul>
  </div>
  <div>
    {{'PROFILE_PAGE.T_WORK_HISTORY' | translate}}
    <a
      class="text-title"
      style="cursor: pointer"
      (click)="isWorkingCollapsed = !isWorkingCollapsed"
      [attr.aria-expanded]="isWorkingCollapsed"
      aria-controls="collapseExample"
    >
    {{ isWorkingCollapsed ? ('PROFILE_PAGE.HIDE' | translate) : ('PROFILE_PAGE.SHOW' | translate) }}
    </a>
    <div
      id="col1"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="!isWorkingCollapsed"
    >
      <ul *ngIf="historyWorking.length > 0">
        <li *ngFor="let item of historyWorking">
          {{ item.startDate | dateThai }} - {{ item.endDate | dateThai }} :
          {{ item.secRoleNameTh }} - {{ item.companyNameTh }}
        </li>
      </ul>
      <ul *ngIf="historyWorking.length == 0">
       -
      </ul>
    </div>
  </div>
</div>
