<div>
    <div>
        <label>{{'PROFILE_PAGE.T_PLACE_OF_EMPLOYMENT' | translate}}</label>
        <p *ngIf="companyStaff.length < 1">-</p>
        <div><span *ngFor="let item of companyStaff">
                <ul>
                    <li>
                        {{item.company.nameTh}}
                    </li>
                </ul>
            </span>
        </div>
    </div>
    <div>
        <label>{{'PROFILE_PAGE.T_AGENT' | translate}}</label>
        <p *ngIf="companyAgent.length < 1">-</p>
        <div><span *ngFor="let item of companyAgent">
                <ul>
                    <li>
                        {{item.company.nameTh}} ({{ item.startDate | dateThai }} -
                        {{
                        item.endDate != null ? (item.endDate | dateThai) : ('PROFILE_PAGE.IS_WORK' | translate)
                        }}
                        )
                    </li>
                </ul>
            </span>
        </div>
    </div>
    <div *ngIf="companyStaff.length > 0">
        <button type="button" class="btn btn-block un-submit bg-primary"
            (click)="edit()">{{'PROFILE_PAGE.B_CHANGE_CONPANY' | translate}}</button>
    </div>
</div>