import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "@app/app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OtpShareComponent } from './otp-share/otp-share.component';
import { CreatePinShareComponent } from './create-pin-share/create-pin-share.component';
import { InstructionalShareComponent } from './instructional-share/instructional-share.component';
import { MyCourseShareComponent } from './my-course-share/my-course-share.component';
import { MyCourseDetailShareComponent } from './my-course-share/my-course-detail-share/my-course-detail-share.component';
import { NgbCollapseModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { TabCourseDetailComponent } from './my-course-share/my-course-detail-share/tab-course-detail/tab-course-detail.component';
import { TabCourseCheckinCheckoutComponent } from './my-course-share/my-course-detail-share/tab-course-checkin-checkout/tab-course-checkin-checkout.component';
import { TabCourseLocationDetailComponent } from './my-course-share/my-course-detail-share/tab-course-location-detail/tab-course-location-detail.component';
import { CourseDetailBannerComponent } from './my-course-share/my-course-detail-share/course-detail-banner/course-detail-banner.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConsentByTypeComponent } from './consent-by-type/consent-by-type.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MyCourseDetailBannerComponent } from '@protected/my-course/my-course-detail/my-course-detail-banner/my-course-detail-banner.component';
import { BehingIdCardModalComponent } from './behing-id-card-modal/behing-id-card-modal.component';
import { MiddleNameModalComponent } from './middle-name-modal/middle-name-modal.component';
import { InstructionalLicenseShareComponent } from './instructional-license-share/instructional-license-share.component';
import { SearchApprovalModelComponent } from './search-approval-model/search-approval-model.component';
import { OtpForeignerComponent } from './otp-foreigner/otp-foreigner.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@app/translate-loader';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        CreatePinShareComponent,
        InstructionalShareComponent,
        MyCourseDetailShareComponent,
        TabCourseCheckinCheckoutComponent,
        TabCourseDetailComponent,
        TabCourseLocationDetailComponent,
        ConsentByTypeComponent,
        InstructionalLicenseShareComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbNavModule,
        NgbCollapseModule,
        PdfJsViewerModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient]
            }
        })

    ],
    declarations: [CreatePinShareComponent,
        InstructionalShareComponent,
        MyCourseShareComponent,
        MyCourseDetailShareComponent,
        TabCourseDetailComponent,
        TabCourseCheckinCheckoutComponent,
        TabCourseLocationDetailComponent,
        CourseDetailBannerComponent,
        ConsentByTypeComponent,
        BehingIdCardModalComponent,
        MiddleNameModalComponent,
        InstructionalLicenseShareComponent,
        SearchApprovalModelComponent

    ]
})
export class SharedModule { }
