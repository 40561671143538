import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CategoryModel, Configuration, CourseActivityControllerService, PublicTrainingProviderControllerService, TrainingProviderModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import { environment } from 'environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-public-course',
  templateUrl: './public-course.component.html',
  styleUrls: ['./public-course.component.scss']
})
export class PublicCourseComponent implements OnInit {
  datatable: DataTables.Api;
  isSetTable: boolean = false;
  exportButtonClassId: number = Math.floor(Math.random() * 999999);
  listOfCategory: CategoryModel[] = [];
  listTrainingProvider: TrainingProviderModel[] = [];
  selectedCategories: CategoryModel[] = [];
  selectedTrainingProvider: TrainingProviderModel[] = [];
  filterForm: FormGroup;
  dropdownSettingsCategory: IDropdownSettings = {
    singleSelection: false,
    idField: 'categoryId',
    textField: 'categoryName',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  dropdownSettingsTrainingProvider: IDropdownSettings = {
    singleSelection: false,
    idField: 'trainingProviderId',
    textField: 'trainingProviderName',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  drawCallbackBlock: () => void;
  constructor(private _courseActivityControllerService: CourseActivityControllerService,
    private fb: FormBuilder,
    private _datePipe: DatePipe,
    private _apiConfig: Configuration,
    private publicTrainingProviderControllerService:PublicTrainingProviderControllerService) { }

  ngOnInit(): void {
    this.createForm();
    this._courseActivityControllerService.getListCategoryUsingGET().subscribe((res) => {
      this.listOfCategory = res.data;
    })
    this.publicTrainingProviderControllerService.getAllTrainingProviderByTrainingProviderTypeUsingPOST({trainingProviderType: 1}).subscribe((res) => {
      this.listTrainingProvider = res.data;
    });
  }

  onFilterChanged() {
    this.datatable.draw();
  }

  createForm() {
    this.filterForm = this.fb.group({
      trainingProvider: [[]],
      areaOfKnowledge: [[]],
      courseName:[''],
    });
  }

  onSearch(){
    if(!this.isSetTable){
      this.setDatatable();
    }else{
      this.onFilterChanged();
    }
    
  }

  setDatatable() {
    let _slThis = this;
    this.isSetTable = true;
    this.datatable = $('#public-course').DataTable({
      info: true,
      language: {
        infoFiltered: ""
      },
      select: {
        style: 'single',
        items: 'cell'
      },
      searching: false,
      responsive: true,
      serverSide: true,
      ajax: {
        url: `${environment.baseUrl}/api/public/v1/course/dt-datasource`,
        type: 'GET',
        headers: { 'Authorization': ('bearer ' + this._apiConfig.accessToken) },
        data: function (d: any) {
          // Convert the bracket notation to dot notation
          let params = {};

          // Iterate over the columns array and reformat the parameters
          d.columns.forEach((col: any, index: any) => {
            params[`columns[${index}].data`] = col.data;
            params[`columns[${index}].name`] = col.name;
            params[`columns[${index}].searchable`] = col.searchable;
            params[`columns[${index}].orderable`] = col.orderable;
            params[`columns[${index}].search.value`] = col.search.value;
            params[`columns[${index}].search.regex`] = col.search.regex;
          });

          // Add other necessary parameters
          params['draw'] = d.draw;
          params['order[0].column'] = d.order[0].column;
          params['order[0].dir'] = d.order[0].dir;
          params['start'] = d.start;
          params['length'] = d.length;
          params['search.value'] = d.search.value;
          params['search.regex'] = d.search.regex;

          // Add filter parameters
          params["customParams[0]"] = `${_slThis.filterForm.value.trainingProvider.map((u: TrainingProviderModel) => u.trainingProviderId).join(', ')}`;
          params["customParams[1]"] = `${_slThis.filterForm.value.areaOfKnowledge.map((u: CategoryModel) => u.categoryId).join(', ')}`;
          params["customParams[2]"] = `${_slThis.filterForm.value.courseName}`;

          return params;
        }
      },
      // createdRow: (row: Node, data: any[] | object, index: number) => {
      //   // $(row).css('text-align', 'center');
      // },
      columns: [
        {
          className: 'custom-header',
          data: 'courseRank',
          orderable: true,
          searchable: false,
          width: '5%',
          render: (data: any, type: any, row: any) => {
            return row.courseRank;
          }
        },
        {
          className: 'custom-header',
          data: 'trainingProviderName',
          orderable: false,
          searchable: false,
          width: '30%',
          render: (data: any, type: any, row: any) => {
            return '<div style="text-align:start;"><span>' + row.trainingProviderName + '</span><br><span><b>'+row.courseName+'</b></span></div>';
          }
        }
        , {
          className: 'custom-header center-tr',
          data: 'areasOfKnowledge',
          orderable: false,
          searchable: false,
          width: '30%',
          render: (data: any, type: any, row: any) => {
            if(row.areasOfKnowledge == null || row.areasOfKnowledge.lenght == 0){
              return '';
            }
            let result = '<ul>'
            row.areasOfKnowledge.forEach((element: any) => {
              let formatTime = `${element.categoryCreditHrs.toString().padStart(2, '0')}:${element.categoryCreditMins.toString().padStart(2, '0')}`
              result += '<li>'+element.categoryName +'<span> ('+formatTime+')</span></li>';
            });
            result += '</ul>';
            return result;
          }
        }, {
          className: 'custom-header',
          data: 'approvedDate',
          orderable: true,
          searchable: false,
          width: '12%',
          render: (data: any, type: any, row: any) => {
            if (row.approvedDate != null) {
              let result = this._datePipe.transform(new Date(row.approvedDate), 'dd/MM/yyyy HH:mm');
              return '<div style="text-align:center;"><span>' + result + '</span></div>';
            } else {
              return '';
            }
          }
        }, {
          className: 'custom-header',
          data: 'tsicasActive',
          orderable: true,
          searchable: false,
          width: '5%',
          render: (data: any, type: any, row: any) => {
            if(row.tsicasActive){
              return 'Active'
            }else{
              return 'Inactive'
            }
          }
        }
      ],
      "dom": '<"top d-flex justify-content-end align-items-center"Blf>rt<"bottom d-flex justify-content-between"ip><"clear">',
      buttons: [
        {
          extend: 'excelHtml5',
          text: 'Export',
          className: `button-display-none ${_slThis.exportButtonClassId}`,
          exportOptions: {
            modifier: {
              page: 'All'
            },
            stripNewlines: false,
            orthogonal: 'export'
          },
        },
        {
          extend: 'csv',
          text: '<i class="far fa-file-excel"></i>',
          className: `btn export-button`,
          action: function (e, dt, button, config) {
            // Add code to make changes to table here
            let currentPageLength = _slThis.datatable.settings().page.len();
            _slThis.datatable.settings().page.len(-1);
            _slThis.drawCallbackBlock = function () {
              $(`.${_slThis.exportButtonClassId}`).click();

              // Set page config back to original value
              setTimeout(() => {
                _slThis.drawCallbackBlock = null;
                _slThis.datatable.settings().page.len(currentPageLength);
                _slThis.datatable.draw();
              }, 200);
            }
            _slThis.datatable.draw();
          }
        }
      ],
      drawCallback() {
        _slThis.drawCallback()
      },
      lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
      order: [[0, 'desc']],
      createdRow: function( row, data, dataIndex ) {
             if ( dataIndex % 2 == 0 ) {        
         $(row).css('background-color','#fff8e6');
     
       }
    }
    });


  }
  drawCallback() {
    if (this.drawCallbackBlock != null) {
      this.drawCallbackBlock();
    }
  }

  onItemSelectTrainerProvider(item: any) {
    this.selectedTrainingProvider.push(item);
    this.filterForm.get('trainingProvider').setValue(this.selectedTrainingProvider);
  }

  onItemDeSelectTrainerProvider(item: any) {
    // Find the index of the item that should be removed
    const index = this.selectedTrainingProvider.findIndex(element => element.trainingProviderId === item.trainingProviderId);
    if (index !== -1) {
      this.selectedTrainingProvider.splice(index, 1); // Remove the item if found
    }
    this.filterForm.get('trainingProvider').setValue(this.selectedTrainingProvider);
  }

  onItemSelectAreaOfKnowledge(item: any) {
    this.selectedCategories.push(item);
    this.filterForm.get('areaOfKnowledge').setValue(this.selectedCategories);
  }

  onItemDeSelectAreaOfKnowledge(item: any) {
    // Find the index of the item that should be removed
    const index = this.selectedCategories.findIndex(element => element.categoryId == item.categoryId);
    if (index !== -1) {
      this.selectedCategories.splice(index, 1); // Remove the item if found
    }
    this.filterForm.get('areaOfKnowledge').setValue(this.selectedCategories);
  }

  onReset(){
    this.filterForm.reset({
      trainingProvider:[],
      areaOfKnowledge: [],
      courseName:''
    });
    this.selectedCategories = [];
    this.selectedTrainingProvider = [];
    this.onFilterChanged();
  }

}
