import { Component, OnInit } from '@angular/core';
import { IcProfileV2ControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileV2Controller.service';
import { IcLicenseSummaryV2Result } from '@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseSummaryV2Result';
import * as WSCall from '@app/utils/WSCall';
import { IcLicenseDetailV2Result } from '@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseDetailV2Result';
import CategoryTypeEnum = IcLicenseDetailV2Result.CategoryTypeEnum;
import { IcProfileControllerService, IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-license-status-detail',
  templateUrl: './license-status-detail.component.html',
  styleUrls: ['./license-status-detail.component.scss'],
})
export class LicenseStatusDetailComponent implements OnInit {
  constructor(
    private _icProfileV2ControllerService: IcProfileV2ControllerService,
    private _icProfileControllerService: IcProfileControllerService,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  icLicenseSummaryV2Result: IcLicenseSummaryV2Result;

  licenseGeneral: Array<IcLicenseDetailV2Result> = [];
  licenseEthic: Array<IcLicenseDetailV2Result> = [];
  licenseEsg: Array<IcLicenseDetailV2Result> = [];
  privilegeDescription: string;
  isHavePrivilege: Boolean;
  title = 'สถานะการอบรมต่ออายุใบอนุญาต*';

  public totalAcquiredMinutestStatusHexColor: string = '#D32F2F';
  public totalAcquiredMinutesTextStatusHexColor: string = '#FFEBEE';

  platform: string = "";

  ngOnInit() {
    this._translate.get('MENU_BAR.LICENSE_STATUS_PAGE').subscribe((res: string) => {
      this.title = res;
    });
    this.platform = localStorage.getItem("platform");
    if (this.platform == null) {
      this.platform = "";
    }

    this.loadData();
  }

  async loadData(): Promise<any> {
    let icId = +localStorage.getItem('ic_id');
    if (icId === 0) {
      return null;
    }

    let icProfile: IcProfileModel = await this.getIcProfileById(icId);
    switch (icProfile?.privilegeType) {
      case 'MD':
        this.isHavePrivilege = true;
        this.privilegeDescription = 'ไม่มีจํานวนชั่วโมงที่ต้องอบรมเพิ่มเติม เนื่องจากท่านใช้สิทธิ์การดํารงสถานภาพเป็นผู้จัดการบริษัทหลักทรัพย์หรือบุคคลที่รับผิดชอบสูงสุดในสายงานเกี่ยวกับตลาดทุน';
        break;
      case 'FM':
        this.isHavePrivilege = true;
        this.privilegeDescription = 'ไม่มีจํานวนชั่วโมงที่ต้องอบรมเพิ่มเติม เนื่องจากท่านใช้สิทธิ์การดํารงสถานภาพผู้จัดการกองทุน';
        break;
      default:
        this.isHavePrivilege = false;
        this.privilegeDescription = '';
        break;
    }

    let icLicenseSummaryV2Result: IcLicenseSummaryV2Result =
      await this.viewLicenseDetail();
    this.icLicenseSummaryV2Result = icLicenseSummaryV2Result;

    console.log(icLicenseSummaryV2Result);

    this.totalAcquiredMinutestStatusHexColor = this.argbToRGB(
      icLicenseSummaryV2Result.totalAcquiredMinutestStatusHexColor
    );
    this.totalAcquiredMinutesTextStatusHexColor = this.argbToRGB(
      icLicenseSummaryV2Result.totalAcquiredMinutesTextStatusHexColor
    );

    this.processLicenseByCategoryType(icLicenseSummaryV2Result.licenseDetails);

    console.log(this.licenseGeneral);
  }

  processLicenseByCategoryType(licenseDetails: Array<IcLicenseDetailV2Result>) {
    licenseDetails.forEach((o) => {
      if (o.categoryType === CategoryTypeEnum.ETHIC) {
        this.licenseEthic.push(o);
      } else if (o.categoryType === CategoryTypeEnum.ESG) {
        this.licenseEsg.push(o);
      } else {
        this.licenseGeneral.push(o);
      }
    });
  }
  async getIcProfileById(icProfileId: number): Promise<IcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getIcProfileByIdUsingGET(
          icProfileId
        );
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }
  async viewLicenseDetail(): Promise<IcLicenseSummaryV2Result> {
    return WSCall.wsCall(
      () => {
        return this._icProfileV2ControllerService.viewLicenseDetailUsingGET1();
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }

  argbToRGB(hex: string) {
    console.log('length :' + hex.length);
    if (hex.length > 7) {
      hex = '#' + hex.substring(3, hex.length);
    }

    return hex;
  }
}
