import { DatePipe } from '@angular/common';
import { RegisterSessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/registerSessionController.service';
import { Component, OnInit } from '@angular/core';
import * as WSCall from '@app/utils/WSCall';
import * as Utils from '@app/utils/Utils.ts';
import { flyInAnimation, flyUpAnimation } from '@shared/animations/animation';
import { NoticeControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/noticeController.service';
import { NoticeModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/noticeModel';
import { QuizInitResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/quizInitResult';
import { SessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service';
import { NewsConfigControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/newsConfigController.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuration, FileStoreModel, NewsModel, NoticeExtensionModel, RegisterSessionModel, TrainingDateModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { log, Console } from 'console';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
  animations: [flyUpAnimation]
})
export class NotificationDetailComponent implements OnInit {


  noticeTypeName: string;
  title: string;
  detail: string;
  myNoticeDetail: NoticeModel;
  quizInitResult: QuizInitResult;
  newsDetail: NewsModel;
  extensionDetail: NoticeExtensionModel;
  resultSessionSelected: RegisterSessionModel;
  noticeID: number;
  icNoticeID: number;
  removeNoticeID: number[];
  isFormActive: boolean;
  actionType: number;
  noticeTypeId: number;
  imageNews: string;

  titlePassword: string = '';
  requireCode: string = '';
  btnConfirm: string = '';
  titleError: string = '';
  msgError: string = '';


  constructor(
    private _noticeControllerService: NoticeControllerService,
    private _sessionControllerService: SessionControllerService,
    private _newsConfigControllerService: NewsConfigControllerService,
    private _registerSessionController: RegisterSessionControllerService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _apiConfig: Configuration,
    private _translate: TranslateService) {
    this._translate.use(localStorage.getItem('language'));
  }


  ngOnInit(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.noticeID = Number(params.get('noticeId'));
    });
    this._activatedRoute.paramMap.subscribe(params => {
      this.icNoticeID = Number(params.get('icNoticeId'));
    });
    this.removeNoticeID = [this.icNoticeID];
    this.loadData();
  }




  async loadData(): Promise<void> {
    this.myNoticeDetail = await this.myNotificationDetail(this.noticeID);
    this.title = this.myNoticeDetail.title;
    this.actionType = this.myNoticeDetail.actionType;
    this.noticeTypeId = this.myNoticeDetail.noticeType.noticeTypeId;
    this.noticeTypeName = this.myNoticeDetail.noticeType.noticeTypeName;

    this.detail = this.myNoticeDetail.detail;

    if (this.myNoticeDetail.entityId > 0) {
      this.isFormActive = await this.isQuizFormActive(this.myNoticeDetail.entityId);
      this.quizInitResult = await this.getEntryCode(this.myNoticeDetail.entityId);
      this.extensionDetail = await this.getNoticeExtension(this.myNoticeDetail.entityId);
      console.log('extensionDetail :', this.extensionDetail);
      if (this.extensionDetail?.image) {
        this.imageNews = this.extensionDetail.image.downloadLink;
      }
    }
    await this.removeMyNotificationByIcNoticeIds(true, this.removeNoticeID);
    console.log('imyNoticeDetail : ', this.myNoticeDetail);
    console.log('quizInitResult', this.quizInitResult);
  }


  async myNotificationDetail(noticeId: number): Promise<NoticeModel> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.getMyNotificationByIcIdAndNoticeIdUsingGET(noticeId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }


  async removeMyNotificationByIcNoticeIds(markAsRead: boolean, icNoticeId: number[]): Promise<number> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.removeMyNotificationByIcNoticeIdsUsingPUT(markAsRead, icNoticeId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async isQuizFormActive(quizFormId: number): Promise<boolean> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.isQuizFormActiveUsingGET(quizFormId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getNewsActivityDetail(newsId: number): Promise<NewsModel> {
    return WSCall.wsCall(
      () => {
        return this._newsConfigControllerService.getNewsByIdUsingGET(newsId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getNoticeExtension(entityId: number): Promise<NoticeExtensionModel> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.getNoticeExtensionUsingGET(entityId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }


  async getEntryCode(quizFormId: number): Promise<QuizInitResult> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getEntryCodeUsingGET(quizFormId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async beginPrePostTest(): Promise<any> {
    this._translate.get('MY_COURSE.BOX_PASSWORD').subscribe((res: string) => {
      this.titlePassword = res;
    });
    this._translate.get('MY_COURSE.REQUIRE_CODE').subscribe((res: string) => {
      this.requireCode = res;
    });
    this._translate.get('BUTTON.CONFIRM').subscribe((res: string) => {
      this.btnConfirm = res;
    });
    const { value: entryCode } = await Swal.fire({
      title: this.quizInitResult.sessionName,
      text: this.titlePassword,
      input: 'text',
      showConfirmButton: true,
      confirmButtonText: this.btnConfirm,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return this.requireCode;
        } else {
          if (value === this.quizInitResult.entryCode) {
            this.beginRedirectUrl();
          } else {
            this._translate.get('UTILS.TITLE_ERROR').subscribe((res: string) => {
              this.titleError = res;
            });
            this._translate.get('UTILS.PASSWORD_ERROR').subscribe((res: string) => {
              this.msgError = res;
            });
            Utils.error(this.msgError, this.titleError);
          }
        }
      }
    });
  }
  getFile(file: FileStoreModel) {
    console.log(file);
    if (file == null) {
      return '';
    } else {
      console.log(file.downloadLink);
      return file.downloadLink;
    }
  }
  async beginRedirectUrl(): Promise<any> {
    {
      // let source = location.origin;
      let source = location.origin + '/notification';
      let url = '';
      if (environment.baseUrl.includes('dev')) {
        url = 'https://dev-proflink.set.or.th';
      } else if (environment.baseUrl.includes('test')) {
        url = 'https://test-proflink.set.or.th';
      } else if (environment.baseUrl.includes('localhost')) {
        url = 'http://localhost:4200';
      } else {
        url = 'https://proflink.set.or.th';
      }
      window.location.href = url +
        '/prepost-test?mobile=1&qf=' +
        this.myNoticeDetail.entityId +
        '&source=' + source +
        '&local=th&token=' +
        this._apiConfig.accessToken;
    }
  }

  async getRegisterSessionBySid(sid: number): Promise<RegisterSessionModel> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getRegisterSessionBySidUsingGET(sid);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  getColorNotice(noticeTypeId: number) {
    let coloCode = '';
    switch (noticeTypeId) {
      case 1:
        coloCode = 'notice_1';
        break;
      case 2:
        coloCode = 'notice_2';
        break;
      case 3:
        coloCode = 'notice_3';
        break;
      case 4:
        coloCode = 'notice_4';
        break;
      case 5:
        coloCode = 'notice_5';
        break;
      case 6:
        coloCode = 'notice_6';
        break;
      default: coloCode = 'notice_0';
    }
    return coloCode;
  }
  setNotitypeNameDistplay(typeName: string) {
    if (typeName.length > 37) {
      return this.noticeTypeName = typeName.substring(0, 37) + '...';
    } else {
      return this.noticeTypeName = typeName;
    }
  }

  redirectToLicenseRegulationDetail() {
    window.open('https://www.set.or.th/th/education-research/education/professional/training/full-course', '_blank').focus();
  }

  redirectToLicenseStatus() {
    this._router.navigate(["/profile/license-status"]);
  }

  redirectToICProfile() {
    this._router.navigate(["/profile/personal/1"]);
  }

  async redirectToNewsAndActivity() {
    this.newsDetail = await this.getNewsActivityDetail(this.myNoticeDetail.entityId);
    console.log(this.newsDetail);
    if (this.newsDetail != null && (this.newsDetail.url != null || this.newsDetail.url !== '')) {
      window.open(this.newsDetail.url, '_blank').focus();
    } else {
      Utils.error('URL Invalid');
    }
  }

  async redirectNotiExtension() {
    this.extensionDetail = await this.getNoticeExtension(this.myNoticeDetail.entityId);
    console.log(this.extensionDetail);
    if (this.extensionDetail != null && (this.extensionDetail.url != null || this.extensionDetail.url !== '')) {
      window.open(this.extensionDetail.url, '_blank').focus();

    } else {
      Utils.error('URL Invalid');
    }
  }
  redirectToDigitalPassport() {
    this._router.navigate(["/profile/digital-passport"]);
  }

  async redirectToSessionDetail() {
    let pathBack = 'notification';
    this._router.navigate([`/my-course-detail-share/${this.myNoticeDetail.entityId}/1/${pathBack}`]);
  }
}
