import { HttpClient } from '@angular/common/http';
import { ProtectedMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import * as Utils from '@app/utils/Utils';
import { FileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import * as WSCall from '@app/utils/WSCall';
import { Injectable } from '@angular/core';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';

@Injectable()
export class FileUploadService {


    transientFileName: string = null; // It is a file user selecting. This is not yet uploaded to server. (Once uploaded, this field is clear.)
    _uploadedFile: FileStoreModel;
    cvaOnChange: (_: any) => any;
    cvaOnTouched: any;

    uploadWithFile(uploadingFile: File, httpClient: HttpClient, protMiscService: ProtectedMiscControllerService, fileId: number, uploadLinkUrl: string,
        onComplete: () => void = null, onFail: () => void = null) {

        // let uploadingFile = ie.files[0];
        if (uploadingFile.size > 15 * 1024 * 1024) {
            this.transientFileName = null;
            Utils.error("File is too large and cannot be uploaded.");
            if (onFail != null) {
                onFail();
            }
            return;
        }

        // Perform upload
        httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {
            this._uploadedFile = await this.completeUpload(protMiscService, fileId, uploadingFile.name);

            if (onComplete != null) {
                onComplete();
            }

        });
    }


    uploadWithFileIgnoreComplete(uploadingFile: File, httpClient: HttpClient, protMiscService: ProtectedMiscControllerService, fileId: number, uploadLinkUrl: string,
        onComplete: () => void = null, onFail: () => void = null) {

        // let uploadingFile = ie.files[0];
        if (uploadingFile.size > 15 * 1024 * 1024) {
            this.transientFileName = null;
            Utils.error("File is too large and cannot be uploaded.");
            if (onFail != null) {
                onFail();
            }
            return;
        }

        // Perform upload
        httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {

            if (onComplete != null) {
                onComplete();
            }

        });
    }

    async completeUpload(protMiscService: ProtectedMiscControllerService, fileId: number, fileName: string): Promise<FileStoreModel> {
        return WSCall.wsCall(() => {
            return protMiscService.completeUploadUsingPOST(fileId, { fileTitle: fileName, fileName: fileName })
        }, (respBody) => {
            return (respBody.data);
        });
    }

    uploadWithFilePublice(uploadingFile: File, httpClient: HttpClient, publictMiscService: PublicMiscControllerService, fileId: number, uploadLinkUrl: string,
        onComplete: () => void = null, onFail: () => void = null) {

        // let uploadingFile = ie.files[0];
        if (uploadingFile.size > 15 * 1024 * 1024) {
            this.transientFileName = null;
            Utils.error("File is too large and cannot be uploaded.");
            if (onFail != null) {
                onFail();
            }
            return;
        }

        // Perform upload
        httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {
            this._uploadedFile = await this.completeUploadPublice(publictMiscService, fileId, uploadingFile.name);

            if (onComplete != null) {
                onComplete();
            }

        });
    }

    async completeUploadPublice(publicMiscService: PublicMiscControllerService, fileId: number, fileName: string): Promise<FileStoreModel> {
        return WSCall.wsCall(() => {
            return publicMiscService.completeUploadUsingPOST1(fileId, { fileTitle: fileName, fileName: fileName })
        }, (respBody) => {
            return (respBody.data);
        });
    }

}

