<div class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
        <div class="pl-2 pl-lg-3">
            <label class="title-headline-box">{{'HOME_PAGE.TRAINING_RECORD' | translate}}</label>
        </div>
        <!-- <a *ngIf="myActiveSessionResults.length > 1" class="see-more-size" (click)="redirectPage()">ดูทั้งหมด ></a> -->
    </div>
    <div class="d-flex justify-content-center">
        <div *ngIf="myActiveSessionResults.length == 0" class="card custom-card text-center">
            <div class="card-body"><label>{{'HOME_PAGE.TRAINING_UNAVAILABLE' | translate}}</label></div>
        </div>
    
        <div [ngClass]="{'card custom-card mb-3': myActiveSessionResults.length > 0}">
            <ngb-carousel #carouselSessions id="sessionsId">
                <ng-template ngbSlide *ngFor="let m of myActiveSessionResults">
                    <div class="card-body row no-gutters" (click)="redirectToMyCourseDetailShare(m.sessionId)">
                        <div [ngClass]="displayQrCode(m) ? 'col-lg-9' : 'col-12'">
                            <div class="title-topic text-truncate mb-2 cursor-pointer">{{m.sessionName}}</div>
                            <div class="text-truncate"><span class="cursor-pointer"><i class="pi pi-calendar primng-icon-size"></i>
                                    {{getPeriodTrainingDayDisplay(m)}}</span></div>
                            <div class="text-truncate"><span class="cursor-pointer"><i class="pi pi-clock primng-icon-size"></i>
                                    {{getPeriodTrainingTimeDisplay(m)}}</span></div>
                            <div class="text-truncate"><span class="cursor-pointer"><i
                                        class="pi pi-map-marker primng-icon-size"></i>
                                    {{m.trainingLocationName}} </span></div>
                        </div>
                        <div *ngIf="displayQrCode(m)" class="col-lg-3">
                            <div class="row no-gutters text-center cursor-pointer mt-lg-3"
                                (click)="redirectToDigitalPassport()">
                                <div class="col-12">
                                    <img class="passport-img" src="../../../../assets/image/ic_qr_scan.png">
                                </div>
                                <div class="col-12 remark-text text-muted">สแกน</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</div>