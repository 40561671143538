import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import * as WSCall from '@app/utils/WSCall';
import { RegisterSessionV2ControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/registerSessionV2Controller.service';
import { MyActiveSessionResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/myActiveSessionResult';
import { MyHammerConfig } from '@app/utils/my-hammer-config';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Session } from "@app/utils/Constants";
import Direction = Session.Direction;
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-digital-passport-short',
  templateUrl: './digital-passport-short.component.html',
  styleUrls: ['./digital-passport-short.component.scss']
})
export class DigitalPassportShortComponent implements OnInit {

  constructor(
    private _datePipe: DatePipe,
    private _router: Router,
    // private _broadcastV2ControllerService: BroadcastV2ControllerService,
    private _registerSessionV2ControllerService: RegisterSessionV2ControllerService
  ) { }

  myActiveSessionResults: Array<MyActiveSessionResult> = [];
  currentDate: number = new Date().setHours(0, 0, 0, 0);
  @ViewChild('carouselSessions', { static: true }) carousel: NgbCarousel;
  alive: boolean = true;
  result: Direction;

  ngOnInit() { }

  async ngAfterViewInit() {
    this.loadData();
    const hammerConfig = new MyHammerConfig()
    let carouselSessionElement = document.getElementById("sessionsId");
    const hammer = hammerConfig.buildHammer(carouselSessionElement)
    fromEvent(hammer, "swipe").pipe(
      takeWhile(() => this.alive))
      .subscribe((res: any) => {
        this.result = res.deltaX < 0 ? Direction.Left : Direction.Right;
        this.swipeTouch(this.result);
      });
  }

  swipeTouch(result: Direction) {
    if (result == Direction.Left) {
      this.carousel.next();
    } else {
      this.carousel.prev();
    }
  }

  async loadData() {
    const result = await this.myActiveSession();
    this.myActiveSessionResults = result ? result : [];

    console.log("myActiveSessionResults");
    console.log(this.myActiveSessionResults);

  }

  displayQrCode(myActiveSessionResult: MyActiveSessionResult): boolean {
    let firstTrainingDate = myActiveSessionResult.startTrainingDate;
    let trainingDateRemoveTime: number = new Date(firstTrainingDate).setHours(0, 0, 0, 0);
    let endTrainingDate = myActiveSessionResult.endTraningDate;
    let endTrainingDateRemoveTime: number = null;

    if (endTrainingDate != null) {

      endTrainingDateRemoveTime = new Date(endTrainingDate).setHours(0, 0, 0, 0);

    }

    if (this.currentDate === trainingDateRemoveTime) {
      return true;
    } else if (endTrainingDateRemoveTime != null
      && this.currentDate >= trainingDateRemoveTime
      && this.currentDate <= endTrainingDateRemoveTime) {
      return true;
    } else {
      return false;
    }
  }

  getPeriodTrainingTimeDisplay(myActiveSessionResult: MyActiveSessionResult) {

    let displayDate: string = '';
    if (myActiveSessionResult.endPeriodTime != null) {
      displayDate = `${myActiveSessionResult.startPeriodTime} - ${myActiveSessionResult.endPeriodTime}`
    } else {
      displayDate = myActiveSessionResult.startPeriodTime;
    }

    return displayDate;
  }

  getPeriodTrainingDayDisplay(myActiveSessionResult: MyActiveSessionResult) {

    let startTrainingDate: Date = myActiveSessionResult.startTrainingDate;
    let endTrainingDate: Date = myActiveSessionResult.endTraningDate;

    let displayDate: string = '';

    let language = localStorage.getItem('language');
    if (endTrainingDate != null) {
      let firstDayDisplay = this._datePipe.transform(startTrainingDate, 'dd MMM', null, language == 'en' ? 'en' : null);
      let lastDayDisplay = this._datePipe.transform(endTrainingDate, 'dd MMM', null, language == 'en' ? 'en' : null);

      let yearTraining = Number(this._datePipe.transform(startTrainingDate, 'yyyy'));

      yearTraining = language == 'th' ? yearTraining + 543 : yearTraining;
      displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
    } else {

      let dayMonth = this._datePipe.transform(startTrainingDate, 'dd MMM', null, language == 'en' ? 'en' : null);
      let yearTraining = Number(this._datePipe.transform(startTrainingDate, 'yyyy'));
      yearTraining = language == 'th' ? yearTraining + 543 : yearTraining;

      displayDate = dayMonth + ' ' + yearTraining;
    }

    return displayDate;

  }
  redirectPage() {
    this._router.navigate(["mycourse"]);
  }

  redirectToMyCourseDetailShare(sessionId: number) {
    this._router.navigate([`/my-course-detail-share/${sessionId}/1/home`]);
  }

  redirectToDigitalPassport() {

    this._router.navigate(["/profile/digital-passport"]);

  }

  // async newBroadcastV2(searchModel: SearchModel) : Promise<Array<BroadcastResult>> {
  //
  //   return WSCall.wsCall(
  //       () => {
  //         return this._broadcastV2ControllerService.newBroadcastV2UsingPOST(searchModel);
  //       },
  //       respBody => {
  //         return respBody.data;
  //       }
  //   );
  // }

  async myActiveSession(): Promise<Array<MyActiveSessionResult>> {

    return WSCall.wsCall(
      () => {
        return this._registerSessionV2ControllerService.myActiveSessionUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

}
