import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IcProfileModel, RegisterICProfileRequest } from '@proflink/prof-link-web-ic-api-ts-angular';
import { SecCheckIcForeignerQueryModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcForeignerQueryModel';

@Component({
  selector: 'app-register-foreigner-form-result',
  templateUrl: './register-foreigner-form-result.component.html',
  styleUrls: ['./register-foreigner-form-result.component.scss']
})
export class RegisterForeignerFormResultComponent implements OnInit {

  constructor(
    private _datePipe: DatePipe,
  ) { }

  icProfile: IcProfileModel;
  requestRegisterForeigner: SecCheckIcForeignerQueryModel;

  @Input('requestRegisterForeigner')
  set setRegisterForm(secCheckIcForeignerQueryModel: SecCheckIcForeignerQueryModel) {
    if (secCheckIcForeignerQueryModel != null) {
      this.requestRegisterForeigner = secCheckIcForeignerQueryModel;
    }
  }

  @Input("icProfile")
  set setIcProfile(icProfileModel: IcProfileModel) {
    if (icProfileModel != null) {
      this.icProfile = icProfileModel;
    }
  }

  @Output('completeState')
  completeState: EventEmitter<Boolean> = new EventEmitter();
  userName: string;
  nationality: string;
  passportNo: string;
  expiryDate: string;
  approvalNo: string;

  userNameSec: string = '';
  nationalitySec: string = '';
  passportNoSec: string = '';
  expiryDateSec: string = '';

  isConfirmed: boolean = false;


  ngOnInit(): void {
    if (this.requestRegisterForeigner) {
      this.userName = (this.requestRegisterForeigner.title == "1" ? 'Mr.' : 'Mrs.') + ' ' + this.requestRegisterForeigner.firstName + ' ' + this.requestRegisterForeigner.lastName;
      this.nationality = this.requestRegisterForeigner.nationalityName;
      this.passportNo = this.requestRegisterForeigner.passportNo;
      this.expiryDate = this.requestRegisterForeigner.dateOfExpiry;
      this.approvalNo = this.requestRegisterForeigner.approvalNo;
    }
    if (this.icProfile) {
      let nameTitle = (this.icProfile.nameTitle.titleEn != null || this.icProfile.nameTitle.titleEn != '') ? this.icProfile.nameTitle.titleEn : this.icProfile.nameTitle.titleTh;
      let firstName = (this.icProfile.secFirstNameEn != null || this.icProfile.secFirstNameEn != '') ? this.icProfile.secFirstNameEn : this.icProfile.secFirstNameTh;
      let lastName = (this.icProfile.secLastNameEn != null || this.icProfile.secLastNameEn != '') ? this.icProfile.secLastNameEn : this.icProfile.secLastNameTh;

      this.userNameSec = nameTitle + ' ' + firstName + ' ' + lastName;
      this.nationalitySec = this.icProfile.nationality.nationalityEn;
      this.passportNoSec = this.icProfile.secPassport;
      if (this.icProfile.passportExpiredDate != null) {
        let expiry = this._datePipe.transform(this.icProfile.passportExpiredDate, 'yyyy-MM-dd');
        this.expiryDateSec = expiry
      } else {
        this.expiryDateSec = '-';
      }

    }
  }

  confirmProfile() {
    if (this.isConfirmed) {
      this.completeState.emit(true);
    }
  }

  onBack() {
    this.completeState.emit(false);
  }

}
