import { Component, OnInit } from '@angular/core';
import { flyOutAnimation, flyUpAnimation } from '@app/shared/animations/animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [flyOutAnimation]
})
export class ProfileComponent implements OnInit {

  title = 'ข้อมูล';

  constructor(
    private _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._translate.get('MENU_BAR.DATA').subscribe((text: string) => {
      this.title = text;
    });
  }

}
