<form [formGroup]="form">
    <div>
        <p>{{'PROFILE_PAGE.T_SELECT_CONPANY' | translate}}</p>
    </div>

    <div>
        <label>{{'PROFILE_PAGE.T_PLACE_OF_EMPLOYMENT' | translate}}</label>
        <div *ngFor="let item of companyStaffActive" class="form-check pt-1">
            <div class="card card-body  custom-div form-check">
                <label class="pr-3 pl-3 form-check-label">
                    <input type="checkbox" *ngIf="item.hrMOU" [id]="item.company.companyId" class="form-check-input" (click)="onChecked($event,item)" [value]="item.company.companyId" name="companyId">{{item.company.nameTh}}
                </label>
            </div>
        </div>

    </div>

    <div>
        <label>{{'PROFILE_PAGE.T_AGENT' | translate}}</label>
        <p *ngIf="companyAgent.length < 1">-</p>
        <div *ngFor="let item of companyAgent">
            <ul>
                <li>
                    {{item.company.nameTh}} ({{ item.startDate | dateThai }} -
                    {{
                    item.endDate != null ? (item.endDate | dateThai) : ('PROFILE_PAGE.IS_WORK' | translate)
                    }}
                    )
                </li>
            </ul>
        </div>
    </div>
</form>
