<div class="list-group">
    <a [routerLink]="'personal/1'" class="list-group-item list-group-item-action">
        <i class="far fa-user"></i>
        <span>&nbsp;&nbsp; {{'MENU_BAR.PROFILE' | translate}}</span>
    </a>
    <a [routerLink]="'digital-passport'" class="list-group-item list-group-item-action">
        <i class="fa fa-qrcode"></i>
        <span>&nbsp;&nbsp; {{'MENU_BAR.DIGITAL_PASSPORT' | translate}}</span>
    </a>
    <a [routerLink]="'license-status'" class="list-group-item list-group-item-action">
        <i class="far fa-address-card"></i>
        <span>&nbsp;&nbsp; {{'MENU_BAR.LICENSE_STATUS' | translate}}</span>
    </a>
</div>