import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-menu-list',
  templateUrl: './profile-menu-list.component.html',
  styleUrls: ['./profile-menu-list.component.scss']
})
export class ProfileMenuListComponent implements OnInit {

  constructor(
    private _translate: TranslateService
  ) {
    _translate.use(localStorage.getItem('language'));
  }

  ngOnInit(): void {
  }

}
