<div class="wrapper" [@flyInAnimation]="'in'">
    <app-header-with-back class="header header-green" [title]="title"
        path="profile/digital-passport"></app-header-with-back>

    <div class="box-container">
        <div class="box">
            <div *ngIf="checkInCheckoutState == 'SCAN_QR_CODE'" class="d-flex flex-column justify-content-center">
                <div clas="p-0">
                    <div class="pt-5 text-center">
                        <label class="title-scan">{{'DIGITAL_PASSPORT_PAGE.T_SCAN_OR_SELECT' | translate}}</label>
                    </div>
                </div>

                <div clas="pt-5">
                    <div class="m-4 d-flex flex-row justify-content-center">
                        <div style="max-width: 500px">
                            <zxing-scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>
                        </div>
                    </div>
                </div>

                <div class="p-0 d-flex flex-row justify-content-center">
                    <label for="inputQrCode" class="btn btn-primary mr-1 ml-1">{{'DIGITAL_PASSPORT_PAGE.T_UPLOAD' |
                        translate}}</label>
                </div>

                <div class="pt-1">
                    <input type="file" style="visibility:hidden;" accept="image/*" id="inputQrCode"
                        (change)="changeFile($event)">
                    <img id="imgQrPreview" src="" style="display:none;" />
                </div>

            </div>
            <div *ngIf="checkInCheckoutState == 'UPLOAD_IMAGE'" class="d-flex flex-column justify-content-center">

                <div class="pr-1 pl-1 m-2 text-center">
                    <label class="text-title-1">{{'DIGITAL_PASSPORT_PAGE.T_UPLOAD_SCREEN' | translate}}</label>
                </div>

                <div class="pr-1 pl-1 text-center">
                    <a target="_blank" rel="noopener noreferrer"
                        href="https://proflink.set.or.th/checkin-online-example">{{'DIGITAL_PASSPORT_PAGE.T_SAMPLE' |
                        translate}}</a>

                </div>
            </div>
            <div *ngIf="checkInCheckoutState == 'UPLOAD_IMAGE'" class="d-flex flex-column justify-content-center">
                <div clas="p-0 d-flex flex-row justify-content-center">

                    <div class="p-0 d-flex flex-row justify-content-center">
                        <div class="p-0 text-center">
                            <img #imagePreviewUpload src="../../../../../assets/image/bg-gray.png" width="250px"
                                height="250px">
                        </div>
                    </div>

                    <div class="pt-3 text-center">
                        <div class="p-0">
                            <label for="cameraInput" class="btn btn-primary mr-1 ml-1">
                                <i class="fa fa-camera pl-2 pr-2" aria-hidden="true"></i>
                            </label>
                            <label for="cameraInputFile"
                                class="btn btn-primary mr-1 ml-1">{{'DIGITAL_PASSPORT_PAGE.T_UPLOAD_IMG' |
                                translate}}</label>
                        </div>

                        <div class="p-0">
                            <input type="file" style="visibility:hidden;" capture="camera" accept="image/*"
                                id="cameraInput" name="cameraInput" (change)="onFileSelected($event)">
                        </div>

                        <div class="p-0">
                            <input type="file" style="visibility:hidden;" accept="image/*" id="cameraInputFile"
                                name="cameraInput" (change)="onFileSelected($event)">
                        </div>
                    </div>
                </div>

                <div class="p-0 flex-fill text-center mr-2 ml-2">
                    <button class="btn btn-primary btn-block" [disabled]="!completeResult"
                        (click)="nextToResult()">{{'BUTTON.NEXT' | translate}}</button>
                </div>
            </div>
            <div *ngIf="checkInCheckoutState == 'RESULT' && checkinCheckoutOnlineResult != null"
                class="d-flex flex-column justify-content-center">

                <div *ngIf="this.imageUploadComplete != null" class="p-0 pb-2 pt-2 text-center">
                    <img id="imageResult" [src]="this.imageUploadComplete.downloadLink" width="100px" height="100px">
                </div>

                <div class="p-0 d-flex flex-row">

                    <div class="pt-1 d-flex flex-column mr-3 ml-3 flex-fill">
                        <div class="card-body p-0 flex-fill">
                            <div class="p-0 d-flex flex-row justify-content-center">
                                <div class="p-0">
                                    <i class="fas fa-check-circle text-primary pt-1 pr-1"></i>
                                    <label
                                        class="p-0">{{displayTimeSecWithDate(checkinCheckoutOnlineResult.checkedDate)}}</label>
                                </div>
                            </div>

                            <div class="p-0 d-flex flex-row justify-content-center">
                                <label
                                    class="pl-1 {{checkinCheckoutOnlineResult.checkedStatus===true?'text-success':'text-danger'}}">{{resultCheckInOutStatus}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-0 d-flex flex-column">
                    <div class="p-0 card card-body m-3">
                        <div class="pt-2 mr-3 ml-3">
                            <div class="p-0 color-title">Session Name</div>
                            <div class="pl-2">{{checkinCheckoutOnlineResult.sessionName}}</div>
                        </div>

                        <div class="p-0 mr-3 ml-3">
                            <div class="p-0 color-title">Name</div>
                            <div class="pl-2">{{checkinCheckoutOnlineResult.fullName}}</div>
                        </div>

                        <div class="p-0 mr-3 ml-3">
                            <div class="p-0 color-title">Date</div>
                            <div class="pl-2">{{displayDate(checkinCheckoutOnlineResult.checkedDate)}}</div>
                        </div>

                        <div class="p-0 mr-3 ml-3">
                            <div class="p-0 color-title">Time</div>
                            <div class="pl-2">{{displayBetweenCheckinCheckout(checkinCheckoutOnlineResult) }}</div>
                        </div>


                        <div class="p-0 mr-3 ml-3">
                            <div class="p-0 color-title">Check-in/Check-out</div>
                            <div *ngIf="checkinCheckoutOnlineResult.checkedType == 'Checkin'" class="pl-2">Check-in
                                {{displayTimeWithDate(checkinCheckoutOnlineResult.checkedDate)}}</div>
                            <div *ngIf="checkinCheckoutOnlineResult.checkedType == 'Checkout'" class="pl-2">Check-out
                                {{displayTimeWithDate(checkinCheckoutOnlineResult.checkedDate)}}</div>
                        </div>
                    </div>

                    <div class="p-0 flex-fill text-center mr-3 ml-3">
                        <button class="btn btn-primary btn-block"
                            (click)="nextToDigitalPassport()">{{'BUTTON.ACKNOWLEDGE' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>