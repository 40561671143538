<div class="modal-body">
    <div class="text-center pt-3">
        <img src="assets/icons/info-circle.svg" alt="info-circle" class="mx-auto img-fluid" style="max-width: 50%;">
        <p class="my-3">{{'E_LEARNING_PAGE.MODAL_HEDER' | translate}}</p>
        <div class="text-danger font-weight-bold">{{'E_LEARNING_PAGE.MODAL_DESCRIPTION' | translate}}</div>
    </div>
    <div class="form-group pt-3 pb-3 text-center">
        <button type="button" class="btn btn-outline-primary btn-next" style="width: 100px;"
            (click)="activeModal.close(true)">
            <h4 class="m-0">{{'BUTTON.ACKNOWLEDGE' | translate}}</h4>
        </button>
    </div>
</div>