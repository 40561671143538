import { Component, Input, OnInit } from '@angular/core';
import * as WSCall from '@app/utils/WSCall';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { flyOutAnimation, flyUpAnimation } from '@shared/animations/animation';

import { NoticeControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/noticeController.service';
import { NoticeModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/noticeModel';
import { promise } from 'protractor';
import { LocalDatePipe } from '@app/utils/local-date-pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [flyUpAnimation]
})
export class NotificationComponent implements OnInit {

  constructor(
    private _router: Router,
    private _noticeControllerService: NoticeControllerService,
    private _localDate: LocalDatePipe,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  title = 'การแจ้งเตือน';
  myNoticeAll: Array<NoticeModel> = [];
  myNoticeDetail: NoticeModel;
  selected: boolean;

  info = 'info';

  ngOnInit() {
    this._translate.get('MENU_BAR.NOTIFICATION').subscribe((text: string) => {
      this.title = text;
    });
    this.loadData();
  }

  async loadData() {
    this.myNoticeAll = await this.myNotification();
  }

  async myNotification(): Promise<Array<NoticeModel>> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.getMyNotificationByIcIdUsingGET();
      },
      respBody => {
        return respBody.data.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
      }
    );
  }
  getColorNotice(notice: NoticeModel) {
    let coloCode = '';
    switch (notice.noticeType.noticeTypeId) {
      case 1:
        coloCode = 'notice_1';
        break;
      case 2:
        coloCode = 'notice_2';
        break;
      case 3:
        coloCode = 'notice_3';
        break;
      case 4:
        coloCode = 'notice_4';
        break;
      case 5:
        coloCode = 'notice_5';
        break;
      case 6:
        coloCode = 'notice_6';
        break;
      default: coloCode = 'notice_0';
    }
    return coloCode;
  }

  getImageNotice(notice: NoticeModel) {
    let coloCode = '';
    if (notice.actionType == 4) {
      coloCode = 'assets/image/noti_type_4.png';
    } else if (notice.actionType == 6) {
      coloCode = 'assets/image/noti_type_6.png';
    } else {
      coloCode = 'assets/image/noti_type_1.png';
    }

    return coloCode;
  }

  getDisplayDate(date: Date) {
    return this._localDate.transform(date, 'dd/MM/yyyy, HH:mm');
  }
}
