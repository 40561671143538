<div>
    <div>
        <label>{{'PUBLISH.MOBILE_NUMBER' | translate}}</label>
        <p>{{profile.mobileNumber}}</p>
    </div>
    <div>
        <label>{{'PUBLISH.EMAIL' | translate}}</label>
        <p>{{profile.email}}</p>
    </div>
    <button type="button" class="btn btn-block un-submit bg-primary" (click)="edit()">{{'BUTTON.CHANGE_DATA' |
        translate}}</button>
</div>