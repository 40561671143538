<div class="col-sm-12 card consent">
    <div class="card-img-top">
        <div class="pt-3 text-center" style="font-size: 22px;">
            <p>{{'TERM_AND_CONDITION.T_HEADER_1' | translate}}</p>
            <p>{{'TERM_AND_CONDITION.T_HEADER_2' | translate}}</p>
            <p>{{'TERM_AND_CONDITION.T_HEADER_3' | translate}}</p>
        </div>
    </div>
    <!-- <object style="min-height: 90%;" class="card-img-top" [data]="pdfUrl">

    </object> -->
    <div class="bloghtml" [innerHTML]="textHtml"></div>
    <div class="card-body">

        <div class="d-flex justify-content-start">
            <label class="remark-text">{{'TERM_AND_CONDITION.T_DESCRIPTION' | translate}}</label>
        </div>

        <div class="d-flex justify-content-center">
            <div class="form-check form-check-inline">
                <input ngbButtonLabel class="form-check-input" name="consentRadio" (click)="updateRadio($event)"
                    type="radio" id="accept" value="accept">
                <label ngbButton class="form-check-label" for="accept">{{'BUTTON.ACCEP' | translate}}</label>
            </div>

            <div class="form-check form-check-inline">
                <input ngbButtonLabel class="form-check-input" (click)="updateRadio($event)" name="consentRadio"
                    type="radio" id="notAccept" value="notAccept">
                <label ngbButton class="form-check-label" for="notAccept">{{'BUTTON.NOT_ACCEP' | translate}}</label>
            </div>
        </div>

        <div class="d-flex justify-content-around">
            <div class="p-2 flex-fill">
                <button type="button" [disabled]="radioConsent != 'accept'" (click)="onNext()"
                    class="btn btn-block submit">{{'BUTTON.NEXT' | translate}}</button>
            </div>
        </div>

        <!--        <div class="d-flex justify-content-around">-->
        <!--            <div class="p-2 flex-fill">-->
        <!--                <button type="button" (click)="submitConsent()" class="btn btn-block submit">ยอมรับ</button>-->
        <!--            </div>-->
        <!--            <div class="p-2 flex-fill">-->
        <!--                <button type="button" (click)="unSubmitConsent()" class="btn btn-block un-submit">ไม่ยอมรับ</button>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</div>