import { Component, Inject, OnInit } from '@angular/core';
import * as WSCall from '@app/utils/WSCall';
import { IcProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { IcLicenseModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseModel';

import * as moment from 'moment';

import { AppState } from '@core/app-state';
import { Router } from '@angular/router';
import { forwardRef } from '@angular/core';
import { HomeComponent } from '@protected/home/home.component';
import { DatePipe } from '@angular/common';
import { IcProfileV2ControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileV2Controller.service';
import { LicenseCurrentPeriodResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/licenseCurrentPeriodResult';
import { IcLicensesExpirationResult } from '@proflink/prof-link-web-ic-api-ts-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss'],
})
export class LicenseInfoComponent implements OnInit {
  constructor(
    @Inject(forwardRef(() => HomeComponent))
    private _homeComponent: HomeComponent,
    private _icProfileControllerService: IcProfileControllerService,
    private _datePipe: DatePipe,
    private _icProfileV2ControllerService: IcProfileV2ControllerService,
    private _translate: TranslateService,
  ) { }

  showRenewLicense: boolean = false;
  showExpiredLicense: boolean = false;
  expiredDateDisplay: string = null;
  icLicensesExpiration: IcLicensesExpirationResult
  graphClassDisplay: string;

  logoPathDisplay = '../../../../assets/image/ic_hourglass_black.png';
  logoPathGreen: string = '../../../../assets/image/ic_hourglass_green.png';
  logoPathYellow: string = '../../../../assets/image/ic_hourglass_yellow.png';
  logoPathRed: string = '../../../../assets/image/ic_hourglass_red.png';
  privilegeDescription: string;
  isHavePrivilege: Boolean;
  duration = {
    years: 0,
    months: 0,
    days: 0,
  };

  ngOnInit() { }

  ngAfterViewInit() {
    this.loadData();
  }

  async showLoading(percent: number) {
    let progressBar: HTMLElement = document.getElementById('percentDisplay');
    let progressBarDiv = progressBar.children;
    let rangeDisplay = Math.ceil(progressBarDiv.length * (percent / 100));
    let className = '';
    if (this.icLicensesExpiration.remainingYears > 0 || this.icLicensesExpiration.remainingMonths > 6) {
      className = 'li-green';
      this.graphClassDisplay = 'text-green';
      this.logoPathDisplay = this.logoPathGreen;
    } else if (this.icLicensesExpiration.remainingMonths > 3 && this.icLicensesExpiration.remainingMonths <= 6) {
      className = 'li-yellow';
      this.graphClassDisplay = 'text-yellow';
      this.logoPathDisplay = this.logoPathYellow;
    } else if (this.icLicensesExpiration.remainingMonths <= 3 &&
      (this.icLicensesExpiration.remainingMonths > 0 || this.icLicensesExpiration.remainingDays > 0)) {
      className = 'li-red';
      this.graphClassDisplay = 'text-red';
      this.logoPathDisplay = this.logoPathRed;
    }

    for (let i = 0; i < progressBarDiv.length; i++) {
      if (i < rangeDisplay) {
        if (progressBarDiv[i]) {
          progressBarDiv[i].className = className;
        }
      }
    }
  }

  async loadData() {
    let icLicense: IcLicenseModel = await this.getLicenseInfo();
    await this.calCalculateRemainingExpired(icLicense);
  }

  async calCalculateRemainingExpired(icLicense: IcLicenseModel) {
    let reNewStartDateStr =
      this._homeComponent.appConfig['TSI_MANAGEMENT_LICENSE_RENEW_START_DATE'];
    let reNewFinishDateStr =
      this._homeComponent.appConfig['TSI_MANAGEMENT_LICENSE_RENEW_FINISH_DATE'];

    let licenseCurrentPeriodResult: LicenseCurrentPeriodResult =
      await this.getLicenseCurrentPeriod();

    if (licenseCurrentPeriodResult == null) {
      return;
    }

    let currentDate = new Date();

    // let currentDate = new Date(licenseCurrentPeriodResult.startDate);
    let expiredDate: Date = null;

    if (icLicense.expireDate == null) {
      expiredDate = new Date(licenseCurrentPeriodResult.endDate);
    } else {
      expiredDate = new Date(icLicense.expireDate);
    }

    let start: moment.Moment = moment(currentDate);
    let end: moment.Moment = moment(expiredDate);
    let remainingDate = moment.duration(end.diff(start));

    this.duration = { years: this.icLicensesExpiration.remainingYears, months: this.icLicensesExpiration.remainingMonths, days: this.icLicensesExpiration.remainingDays };
    this.expiredDateDisplay = this.getDisplayDate(this.icLicensesExpiration.expiryDate);

    let percent = Math.floor((remainingDate.asDays() / 730) * 100);
    if (this.icLicensesExpiration.remainingDays > 0 && percent === 0) {
      percent = 1;
    }
    if (percent < 0) {
      this.duration.days = 0;
      this.duration.months = 0;
      this.duration.years = 0;
      this.showRenewLicense = false;
      this.showExpiredLicense = true;
    } else if (expiredDate.getTime() < currentDate.getTime()) {
      this.showRenewLicense = false;
      this.showExpiredLicense = true;
    } else if (reNewStartDateStr != null && reNewFinishDateStr != null) {
      reNewStartDateStr = reNewStartDateStr + currentDate.getFullYear();
      reNewFinishDateStr = reNewFinishDateStr + currentDate.getFullYear();

      let reNewStartDate: Date = moment(reNewStartDateStr, 'DDMMYYYY').toDate();
      let reNewFinishDate: Date = moment(
        reNewFinishDateStr,
        'DDMMYYYY'
      ).toDate();

      if (
        currentDate.getTime() > reNewStartDate.getTime() &&
        currentDate.getTime() < reNewFinishDate.getTime()
      ) {
        this.showRenewLicense = true;
        this.showExpiredLicense = false;
      }
    } else if (reNewStartDateStr != null) {
      reNewStartDateStr = reNewStartDateStr + currentDate.getFullYear();
      let reNewStartDate: Date = moment(reNewStartDateStr, 'DDMMYYYY').toDate();
      if (currentDate.getTime() > reNewStartDate.getTime()) {
        this.showRenewLicense = true;
        this.showExpiredLicense = false;
      }
    }

    this.showLoading(percent);
  }

  async getLicenseInfo(): Promise<IcLicenseModel> {
    let icId = +localStorage.getItem('ic_id');
    if (icId === 0) {
      return null;
    }

    let icProfile: IcProfileModel = await this.getIcProfileById(icId);
    this.icLicensesExpiration = icProfile.icLicensesExpiration;
    let icLicenses = icProfile.icLicenses;
    switch (icProfile?.privilegeType) {
      case 'MD':
        this.isHavePrivilege = true;
        this._translate.get('HOME_PAGE.COMPANY_DIRECTORSHIP_EXEMPTION').subscribe((res: string) => {
          this.privilegeDescription = res;
        });
        break;
      case 'FM':
        this.isHavePrivilege = true;
        this._translate.get('HOME_PAGE.FUND_DIRECTORSHIP_EXEMPTION').subscribe((res: string) => {
          this.privilegeDescription = res;
        });
        break;
      default:
        this.isHavePrivilege = false;
        this.privilegeDescription = '';
        break;
    }
    let using: IcLicenseModel = null;

    if (icLicenses == null) {
      return null;
    }

    for (let i = 0; i < icLicenses.length; i++) {
      let icLicense: IcLicenseModel = icLicenses[i];

      if (icLicense.expireDate == null) {
        using = icLicense;
        break;
      } else if (using == null) {
        using = icLicense;
      } else if (icLicense.expireDate > using.expireDate) {
        using = icLicense;
      }
    }

    return using;
  }

  async getIcProfileById(icProfileId: number): Promise<IcProfileModel> {
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getIcProfileByIdUsingGET(
          icProfileId
        );
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }

  getDurationFromDays(numberOfDays: number, current: Date, expire: Date) {
    let duration = {
      years: 0,
      months: 0,
      days: 0,
    };

    if (numberOfDays < 0) {
      return duration;
    }
    // var time =(expire.getTime() - current.getTime()) / 1000;
    // var years  = Math.abs(Math.round((time/(60 * 60 * 24))/365.25));
    // var months = Math.abs(Math.round(time/(60 * 60 * 24 * 7 * 4)));
    // var days = Math.abs(Math.round(time/(3600 * 24)));
    const moment = require('moment');
    require('moment-precise-range-plugin');

    const firstDate = moment(current);
    const secondDate = moment(expire);

    const diff = secondDate.preciseDiff(current, expire, true);
    console.log(diff);

    duration.years = diff.years;
    duration.months = diff.months;
    duration.days = diff.days;

    return duration;
  }

  getDisplayDate(date: Date) {
    if (date == null) {
      return null;
    }
    let language = localStorage.getItem('language');
    let dateMonthExpired = this._datePipe.transform(date, 'dd MMMM', null, language == 'en' ? 'en' : null);
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = language == 'th' ? yearExpired + 543 : yearExpired;

    let displayDate = dateMonthExpired + ' ' + yearExpired;

    return displayDate;
  }

  async getLicenseCurrentPeriod(): Promise<LicenseCurrentPeriodResult> {
    return WSCall.wsCall(
      () => {
        return this._icProfileV2ControllerService.getLicenseCurrentPeriodUsingGET();
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }
}
