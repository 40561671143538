import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

import { Session } from "@app/utils/Constants";
import Direction = Session.Direction;
import * as WSCall from "@app/utils/WSCall";
import { SessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import { SearchModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/searchModel";
import { ResultSessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { BroadcastResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/broadcastResult';
import { BroadcastV2ControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/broadcastV2Controller.service';
import { MyHammerConfig } from '@app/utils/my-hammer-config';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-upcoming-course',
  templateUrl: './upcoming-course.component.html',
  styleUrls: ['./upcoming-course.component.scss']
})
export class UpcomingCourseComponent implements OnInit {

  constructor(
    config: NgbCarouselConfig,
    private _router: Router,
    private _sessionControllerService: SessionControllerService,
    private _datePipe: DatePipe,
    private _broadcastV2ControllerService: BroadcastV2ControllerService,
  ) {

    config.showNavigationArrows = false;

  }

  @Output('selectedResult')
  selectedResultEventEmitter: EventEmitter<BroadcastResult> = new EventEmitter();
  @Output('upcomingCoursesResult')
  upcomingCoursesEventEmitter: EventEmitter<Array<BroadcastResult>> = new EventEmitter();

  // upcomingCourses: Array<ResultSessionModel> = [];
  broadcastResults: Array<BroadcastResult> = [];
  @ViewChild('carouselBroadcasts', { static: true }) carousel: NgbCarousel;
  alive: boolean = true;
  result: Direction;
  language: string;

  async ngOnInit() {
    this.language = localStorage.getItem('language');
  }

  async ngAfterViewInit() {
    this.loadData();
    const hammerConfig = new MyHammerConfig()
    let carouselBroadcastElement = document.getElementById("broadcastId");
    const hammer = hammerConfig.buildHammer(carouselBroadcastElement)
    fromEvent(hammer, "swipe").pipe(
      takeWhile(() => this.alive))
      .subscribe((res: any) => {
        this.result = res.deltaX < 0 ? Direction.Left : Direction.Right;
        this.swipeTouch(this.result);
      });
  }

  swipeTouch(result: Direction) {
    if (result == Direction.Left) {
      this.carousel.next();
    } else {
      this.carousel.prev();
    }
  }

  async loadData() {
    let icId = +(localStorage.getItem("ic_id"));
    let searchModel: SearchModel = {
      id: icId,
      limitResults: 10
    };
    console.log(icId);
    // this.upcomingCourses = await this.getSessionNewBroadCastByCondition(searchModel);
    const result = await this.getNewBroadcastV2(searchModel);
    this.broadcastResults = result ? result : [];

    this.upcomingCoursesEventEmitter.emit(this.broadcastResults);
  }


  async getSessionNewBroadCastByCondition(searchModel: SearchModel): Promise<Array<ResultSessionModel>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getSessionNewBroadCastByConditionUsingPOST1(searchModel);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  getDetail(broadcastResult: BroadcastResult) {
    let pathBack = 'home';
    this._router.navigate([`/my-course-detail-share/${broadcastResult.sessionId}/0/${pathBack}`]);
  }

  getDisplayDay(date: Date) {
    if (date == null) {
      return null;
    }

    let dateMonthExpired = this._datePipe.transform(date, 'dd MMMM');
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = this.language == 'th' ? yearExpired + 543 : yearExpired;
    let day = this._datePipe.transform(date, 'dd');

    let displayDate = day;
    return displayDate;

  }

  getDisplayMonth(date: Date) {

    if (date == null) {
      return null;
    }

    let month = this._datePipe.transform(date, 'MMM', null, this.language == 'en' ? 'en' : null);
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = this.language == 'th' ? yearExpired + 543 : yearExpired;

    let displayDate = month;

    return displayDate;

  }

  getPeriodTrainingTimeDisplay(broadcastResult: BroadcastResult) {

    let displayDate: string = '';
    if (broadcastResult.endPeriodTime != null) {
      displayDate = `${broadcastResult.startPeriodTime} - ${broadcastResult.endPeriodTime}`
    } else {
      displayDate = broadcastResult.startPeriodTime;
    }

    return displayDate;
  }


  async getNewBroadcastV2(searchModel: SearchModel): Promise<Array<BroadcastResult>> {
    return WSCall.wsCall(
      () => {
        return this._broadcastV2ControllerService.newBroadcastV2UsingPOST(searchModel);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

}
