import { YearThaiPipe } from './utils/pipes/yearThai.pipe';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './protected/layout/layout.component';
import { LoginComponent } from './public/login/login.component';
import { ApiModule, Configuration, ConfigurationParameters, BASE_PATH } from '@proflink/prof-link-web-ic-api-ts-angular';
import { AuthProvider } from '@core/auth/auth-provider.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiModule as AuthApiModule, BASE_PATH as AUTH_BASE_PATH, TokenEndpointService } from '@proflink/prof-link-springsec-web-ic-api-ts-angular';
import { AuthGuard } from '@core/auth/auth-guard.service';
import { AppState } from '@core/app-state';
import { WindowRef } from '@app/utils/Window';
import { environment } from 'environments/environment';
import { AuthInterceptor } from '@core/auth/auth-interceptor';
import { Router } from '@angular/router';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
var sharedApiModuleConfig: Configuration;
import {DatePipe} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import { SharedModule } from "@shared/shared.module";
import { OTPComponent } from "@shared/otp/otp.component";
import { HeaderComponent } from './protected/layout/header/header.component';
import { TabMenuComponent } from './protected/layout/tab-menu/tab-menu.component';
import { HomeComponent } from './protected/home/home.component';
import { SearchComponent } from './protected/search/search.component';
import { MyCourseComponent } from './protected/my-course/my-course.component';
import { ELearningComponent } from './protected/e-learning/e-learning.component';
import { MoreComponent } from './protected/more/more.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewsAndActivityComponent } from './protected/news-and-activity/news-and-activity.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HeaderWithCloseComponent } from './protected/layout/header-with-close/header-with-close.component';
import { MainMenuComponent } from './protected/main-menu/main-menu.component';
import { ProfileComponent } from './protected/profile/profile.component';
import { HeaderWithBackComponent } from './protected/layout/header-with-back/header-with-back.component';
import { MoreMenuListComponent } from './protected/more/more-menu-list/more-menu-list.component';
import { PrivacySettingComponent } from './protected/more/more-menu-list/privacy-setting/privacy-setting.component';
import { ChangePinComponent } from './protected/more/more-menu-list/privacy-setting/change-pin/change-pin.component';
import { LanguageSettingComponent } from './protected/more/more-menu-list/language-setting/language-setting.component';
import { ContactUsComponent } from './protected/more/more-menu-list/contact-us/contact-us.component';
import { AboutUsComponent } from './protected/more/more-menu-list/about-us/about-us.component';
import { ConditionAndAgreementComponent } from './protected/more/more-menu-list/condition-and-agreement/condition-and-agreement.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { HeaderWithBannerComponent } from './protected/layout/header-with-banner/header-with-banner.component';
import { TimerService } from "@app/service/timer-service";
import { FormatTimePipe } from "@app/utils/format-time-pipe";
import {OtpShareComponent} from "@shared/otp-share/otp-share.component";
import { CreatePinComponent } from './public/create-pin/create-pin.component';
import { RegisterComponent } from './public/register/register.component';
import { IcConsentComponent } from './public/register/ic-consent/ic-consent.component';
import { RegisterFormComponent } from './public/register/register-form/register-form.component';
import { RegisterFormResultComponent } from './public/register/register-form-result/register-form-result.component';
import { RegisterFormCreateComponent } from '@public/register/regiseter-form-create/register-form-create.component';
import { LicenseInfoComponent } from './protected/home/license-info/license-info.component';
import { HeaderWithTitleComponent } from './protected/layout/header-with-title/header-with-title.component';
import { NewsFeedComponent } from './protected/home/news-feed/news-feed.component';
import { NotificationComponent } from './protected/notification/notification.component';
import { NewsAndActivityListComponent } from './protected/news-and-activity/news-and-activity-list/news-and-activity-list.component';
import { NewsAndActivityByCategoryComponent } from './protected/news-and-activity/news-and-activity-by-category/news-and-activity-by-category.component';
import {LocalDatePipe} from "./utils/local-date-pipe";
import { MyCourseListComponent } from './protected/my-course/my-course-list/my-course-list.component';
import { MyCourseDetailComponent } from './protected/my-course/my-course-detail/my-course-detail.component';

import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import localeThExtra from '@angular/common/locales/extra/th';
import { MyCourseListNonProfLinkComponent } from './protected/my-course/my-course-list-non-prof-link/my-course-list-non-prof-link.component';
import {UpcomingCourseComponent} from "@protected/home/upcoming-course/upcoming-course.component";

registerLocaleData(localeTh, 'th-TH', localeThExtra);
import { UpcomingCourseDetailComponent } from './protected/home/upcoming-course/upcoming-course-detail/upcoming-course-detail.component';
import { UpcomingCourseListComponent } from './protected/home/upcoming-course/upcoming-course-list/upcoming-course-list.component';
import { UpcomingCourseSearchComponent } from './protected/home/upcoming-course/upcoming-course-search/upcoming-course-search.component';
import { DigitalPassportShortComponent } from './protected/home/digital-passport-short/digital-passport-short.component';
import { MyCourseDetailBannerComponent } from './protected/my-course/my-course-detail/my-course-detail-banner/my-course-detail-banner.component';
import { BannerProfileComponent } from './protected/home/banner-profile/banner-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfileMenuListComponent } from './protected/profile/profile-menu-list/profile-menu-list.component';
import { DigitalPassportComponent } from './protected/profile/profile-menu-list/digital-passport/digital-passport.component';
import {QRCodeModule} from 'angularx-qrcode';
import {NotificationDetailComponent} from '@protected/notification/notification-detail/notification-detail.component';
import { CheckinCheckoutOnlineComponent } from './protected/profile/profile-menu-list/checkin-checkout-online/checkin-checkout-online.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import { FileUploadComponent } from './public/file-upload/file-upload.component';
import { AttachmentDocumentComponent } from './protected/my-course/my-course-detail/attachment-document/attachment-document.component';
import { CancellationAndPrivacyComponent } from './protected/more/more-menu-list/privacy-setting/cancellation-and-privacy/cancellation-and-privacy.component';
import {loaderConfigConstant} from '@app/constants/loader-config.constant';
import {NgxUiLoaderHttpModule, NgxUiLoaderModule} from 'ngx-ui-loader';
import {LazyLoadImagesModule} from 'ngx-lazy-load-images';
import {FileUploadService} from '@app/service/file-service';
import { LicenseStatusComponent } from './protected/profile/profile-menu-list/license-status/license-status.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import { PersonalProfileComponent } from './protected/profile/profile-menu-list/personal-profile/personal-profile.component';
import { ProfileInformationComponent } from './protected/profile/profile-menu-list/personal-profile/profile-information/profile-information.component';
import { ProfileLicenseComponent } from './protected/profile/profile-menu-list/personal-profile/profile-license/profile-license.component';
import { ProfileCompanyComponent } from './protected/profile/profile-menu-list/personal-profile/profile-company/profile-company.component';
import { ProfileContactComponent } from './protected/profile/profile-menu-list/personal-profile/profile-contact/profile-contact.component';
import { ProfileInformationFormComponent } from './protected/profile/profile-menu-list/personal-profile/profile-information-form/profile-information-form.component';
import { ProfileCompanyFormComponent } from './protected/profile/profile-menu-list/personal-profile/profile-company-form/profile-company-form.component';
import { ProfileContactFormComponent } from './protected/profile/profile-menu-list/personal-profile/profile-contact-form/profile-contact-form.component';
import { LicenseStatusDetailComponent } from './protected/profile/profile-menu-list/license-status-detail/license-status-detail.component';
import { ELearningListComponent } from './protected/e-learning/e-learning-list/e-learning-list.component';
import { DateThaiPipe } from './utils/pipes/date-thai.pipe';
import {HeaderWithBackEmitterComponent} from './protected/layout/header-with-back-emitter/header-with-back-emitter.component';
import { BannerELearningComponent } from './protected/e-learning/banner-e-learning.component';
import { ChangeMobileNumberComponent } from './public/change-mobile-number/change-mobile-number.component';
import { SearchListComponent } from './protected/search/search-list/search-list.component';
import { SearchTrainingProviderListComponent } from './protected/search/search-training-provider-list/search-training-provider-list.component';
import { SearchTrainingProviderDetailComponent } from './protected/search/search-training-provider-detail/search-training-provider-detail.component';
import { ChangeMobileNumberInfoComponent } from './public/change-mobile-number/change-mobile-number-info/change-mobile-number-info.component';
import { ChangeMobileNumberFormComponent } from './public/change-mobile-number/change-mobile-number-form/change-mobile-number-form.component';
import { ChangeMobileNumberConfirmComponent } from './public/change-mobile-number/change-mobile-number-confirm/change-mobile-number-confirm.component';
import {LoginConsentComponent} from '@public/login-consent/login-consent.component';
import { EmailValidateComponent } from './protected/email-validate/email-validate.component';
import { EmailValidateFormComponent } from './protected/email-validate/email-validate-form/email-validate-form.component';
import { EmailValidateConfirmComponent } from './protected/email-validate/email-validate-confirm/email-validate-confirm.component';
import { ReportProblemComponent } from './public/report-problem/report-problem.component';
import { ReportProblemListComponent } from './public/report-problem/report-problem-list/report-problem-list.component';
import { ReportProblemFormComponent } from './public/report-problem/report-problem-form/report-problem-form.component';
import { ShareRegisterConsentComponent } from './public/share-register-consent/share-register-consent.component';

import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import {TabViewModule} from 'primeng/tabview';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import { CarouselModule } from 'primeng/carousel';
import { ELearningResultComponent } from './protected/e-learning/e-learning-result/e-learning-result.component';
import { ELearningService } from './service/e-learning-service';
import { ELearningConsentModalComponent } from './protected/e-learning/e-learning-consent-modal/e-learning-consent-modal.component';
import { RegisterForeignerFormComponent } from './public/register/register-foreigner-form/register-foreigner-form.component';
import { RegisterForeignerFormResultComponent } from './public/register/register-foreigner-form-result/register-foreigner-form-result.component';
import { OtpForeignerComponent } from './shared/otp-foreigner/otp-foreigner.component';
import { RegisterForeignerWaitApproveComponent } from './public/register/register-foreigner-wait-approve/register-foreigner-wait-approve.component';
import { CourseActivitityComponent } from './protected/course-activitity/course-activitity.component';
import { CreateCourseActivityComponent } from './protected/course-activitity/create-course-activity/create-course-activity.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from './translate-loader';
import { LanguageSwitcherComponent } from './public/language-switcher/language-switcher.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PublicCourseComponent } from './public/public-course/public-course.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
  }
  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}



export function init_app(_authProvider: AuthProvider) {
  return () => _authProvider.onAppLoaded();
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        LoginComponent,
        OTPComponent,
        HeaderComponent,
        TabMenuComponent,
        HomeComponent,
        SearchComponent,
        MyCourseComponent,
        ELearningComponent,
        MoreComponent,
        NewsAndActivityComponent,
        HeaderWithCloseComponent,
        MainMenuComponent,
        ProfileComponent,
        HeaderWithBackComponent,
        MoreMenuListComponent,
        PrivacySettingComponent,
        LanguageSettingComponent,
        ContactUsComponent,
        AboutUsComponent,
        ConditionAndAgreementComponent,
        ForgotPasswordComponent,
        HeaderWithBannerComponent,
        FormatTimePipe,
        OtpShareComponent,
        CreatePinComponent,
        RegisterComponent,
        IcConsentComponent,
        RegisterFormComponent,
        RegisterFormResultComponent,
        RegisterFormCreateComponent,
        LicenseInfoComponent,
        HeaderWithTitleComponent,
        NewsFeedComponent,
        NotificationComponent,
        NewsAndActivityListComponent,
        NewsAndActivityByCategoryComponent,
        LocalDatePipe,
        MyCourseListComponent,
        MyCourseDetailComponent,
        MyCourseListNonProfLinkComponent,
        UpcomingCourseComponent,
        UpcomingCourseDetailComponent,
        UpcomingCourseListComponent,
        UpcomingCourseSearchComponent,
        DigitalPassportShortComponent,
        MyCourseDetailBannerComponent,
        BannerProfileComponent,
        ProfileMenuListComponent,
        DigitalPassportComponent,
        ChangePinComponent,
        NotificationDetailComponent,
        AttachmentDocumentComponent,
        NotificationDetailComponent,
        CheckinCheckoutOnlineComponent,
        FileUploadComponent,
        CancellationAndPrivacyComponent,
        LicenseStatusComponent,
        PersonalProfileComponent,
        ProfileInformationComponent,
        ProfileLicenseComponent,
        ProfileCompanyComponent,
        ProfileContactComponent,
        ProfileInformationFormComponent,
        ProfileCompanyFormComponent,
        ProfileContactFormComponent,
        LicenseStatusDetailComponent,
        ELearningListComponent,
        HeaderWithBackEmitterComponent,
        DateThaiPipe,
        YearThaiPipe,
        BannerELearningComponent,
        ChangeMobileNumberComponent,
        SearchListComponent,
        SearchTrainingProviderListComponent,
        SearchTrainingProviderDetailComponent,
        ChangeMobileNumberInfoComponent,
        ChangeMobileNumberFormComponent,
        ChangeMobileNumberConfirmComponent,
        LoginConsentComponent,
        EmailValidateComponent,
        EmailValidateFormComponent,
        EmailValidateConfirmComponent,
        ReportProblemComponent,
        ReportProblemListComponent,
        ReportProblemFormComponent,
        ShareRegisterConsentComponent,
        ELearningResultComponent,
        ELearningConsentModalComponent,
        RegisterForeignerFormComponent,
        RegisterForeignerFormResultComponent,
        OtpForeignerComponent,
        RegisterForeignerWaitApproveComponent,
        CourseActivitityComponent,
        CreateCourseActivityComponent,
        LanguageSwitcherComponent,
        PublicCourseComponent
    ],
    imports: [
        ApiModule.forRoot(apiConfigFactory),
        AuthApiModule.forRoot(apiConfigFactory),
        HttpClientModule,
        BrowserModule,
        AmChartsModule,
        AppRoutingModule,
        SharedModule,
        NgbModule,
        BrowserAnimationsModule,
        CollapseModule.forRoot(),
        ReactiveFormsModule,
        QRCodeModule,
        PdfJsViewerModule,
        QRCodeModule,
        ZXingScannerModule,
        LazyLoadImagesModule,
        NgSelectModule,
        NgxUiLoaderModule.forRoot(loaderConfigConstant),
        NgxUiLoaderHttpModule.forRoot({ showForeground: true, excludeRegexp: [] }),
        MenubarModule,
        TabViewModule,
        MenuModule,
        ButtonModule,
        RippleModule,
        CarouselModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
            deps: [HttpClient]
          }
        }),
        NgMultiSelectDropDownModule.forRoot()
    ],
  providers: [
    AuthGuard,
    AppState,
    WindowRef,
    AuthProvider,
    TimerService,
    FileUploadService,
    ELearningService,
    DatePipe,
    DeviceDetectorService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    LocalDatePipe,
    { provide: LOCALE_ID, useValue: 'th-TH' },
    {
      provide: AUTH_BASE_PATH, useValue: environment.baseUrl
    },
    {
      provide: BASE_PATH, useValue: environment.baseUrl
    },

    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AuthProvider]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, appState: AppState, tokenService: TokenEndpointService) {
        return new AuthInterceptor(router, appState, tokenService);
      },
      useClass: AuthInterceptor,  // if error can be deleted
      multi: true,
      deps: [Router, AppState, TokenEndpointService]
    },
  ],



  exports: [
    HeaderWithBackComponent
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
