import { Component, OnInit } from '@angular/core';
import { flyInAnimation } from '@app/shared/animations/animation';
import { APIResultstring } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import * as WSCall from '@app/utils/WSCall';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  animations: [flyInAnimation]
})
export class AboutUsComponent implements OnInit {

  title = '';

  constructor(
    private _sanitizer: DomSanitizer,
    private _publicMiscControllerService: PublicMiscControllerService,
    private _translate: TranslateService) {
    this._translate.use(localStorage.getItem('language'));
  }

  video: any;
  videoUrl: any;
  async ngOnInit() {
    this._translate.get('MENU_BAR.ABOUT').subscribe((text: string) => {
      this.title = text;
    });
    this.video = await this.getYoutubeVideo();
    let url = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.video);
    // this.videoUrl =
    //   this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/' + this.video);
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.video);
    console.log(url);
    //https://youtu.be/3_qUdY-ZHIA
  }

  async getYoutubeVideo() {
    let result: APIResultstring = await this.getVdoInstructionYoutubeId();
    console.log(result);
    if (result.resultCode === 'SUCCESS') {
      return result.data;
    } else {
      return '0I_3Fl62T54';
    }
  }

  async getVdoInstructionYoutubeId(): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.getLoggedInVdoInstructionCheckLicenseYoutubeIdUsingGET1();
      },
      respBody => {
        return respBody;
      }
    );
  }
  redirectToSETContactCenter() {
    window.open('https://www.set.or.th/contactcenter', '_blank').focus();
  }
}
