import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core/app-state';
import { AuthProvider } from '@app/core/auth/auth-provider.service';
import { TimerService } from '@app/service/timer-service';
import { APIResultstring, AccountControllerService, RegisterICTemporaryAccountModel, ResultOTPModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import { Subscription, interval } from 'rxjs';
import * as WSCall from "@app/utils/WSCall";
import * as Utils from "@app/utils/Utils";

@Component({
  selector: 'app-otp-foreigner',
  templateUrl: './otp-foreigner.component.html',
  styleUrls: ['./otp-foreigner.component.scss']
})
export class OtpForeignerComponent implements OnInit {


  @Input("resultOTP")
  set setResultOtp(resultOTP: ResultOTPModel) {
    this.resultOTP = resultOTP;

    if (this.resultOTP) {
      let expiredSeconds = this.getExpiredTimeOtp(this.resultOTP);
      this.counter = expiredSeconds;
      this.startTimer();
    }
  }

  @Output('successVerifyOtp')
  successVerifyOtp: EventEmitter<string> = new EventEmitter();


  canRequestOtp: boolean = true;

  counter = 0;
  tick = 1000;
  countDown: Subscription;

  resultOTP: ResultOTPModel;
  mobileNumber: string = "";
  refCode: string = "";
  inputOtp: string = '';

  constructor(
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _accountControllerService: AccountControllerService,
    private _router: Router,
    private _timerService: TimerService
  ) { }

  ngOnInit(): void {
  }


  getExpiredTimeOtp(resultOTPModel: ResultOTPModel): number {
    this.mobileNumber = this.resultOTP.mobileNumber;
    this.refCode = this.resultOTP.refCode;
    let currentDate: Date = new Date();
    let expiredDate: Date = new Date(resultOTPModel.expireDatetime);

    let duration = expiredDate.valueOf() - currentDate.valueOf();
    let expiredSeconds = Math.floor(duration / 1000);

    return expiredSeconds;
  }

  startTimer() {
    this.canRequestOtp = false;
    this.countDown = this._timerService
      .getCounter(this.tick)
      .subscribe(() => {
        if (this.counter <= 0) {
          this.canRequestOtp = true;
          this.countDown = null;
        } else {
          this.counter--;
        }
      });
  }

  async requestOtp() {
    if (!this.canRequestOtp) {
      return;
    }

    let icId = this.resultOTP.icId;
    let fromResendOtp: RegisterICTemporaryAccountModel = {
      icId: icId
    };

    let resultOTPModel: ResultOTPModel = await this.resendOTPUsingPOST(fromResendOtp);
    this.resultOTP = resultOTPModel;

    let expiredSeconds = this.getExpiredTimeOtp(this.resultOTP);
    this.counter = expiredSeconds;

    this.startTimer();
  }

  async resendOTPUsingPOST(fromResendOtp: RegisterICTemporaryAccountModel): Promise<ResultOTPModel> {

    return WSCall.wsCall(
      () => {
        return this._accountControllerService.resendOTPUsingPOST(fromResendOtp);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async submitOtp() {
    let icId = this.resultOTP.icId;
    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    let otp = this.inputOtp;

    let formResendOtp: RegisterICTemporaryAccountModel = {
      "mobileNumber": this.resultOTP.mobileNumber,
      "refCode": this.resultOTP.refCode,
      "otpType": this.resultOTP.otpType,
      "pinCodeOTP": otp,
      "uniqueToken": deviceToken,
      "icId": icId
    };
    this.inputOtp = '';

    let resultVerifyOtp: APIResultstring = null;
    console.log("validate otp");
    resultVerifyOtp = await this.verifyOtp(formResendOtp);
    if (resultVerifyOtp.resultCode == 'SUCCESS') {
      this.successVerifyOtp.emit(resultVerifyOtp.data);
    } else {
      this.inputOtp = '';
      Utils.error(resultVerifyOtp.errorMessage, 'เกิดข้อผิดพลาด');
    }

  }

  async verifyOtp(fromResendOtp: RegisterICTemporaryAccountModel): Promise<APIResultstring> {

    return WSCall.wsCall(
      () => {
        return this._accountControllerService.verifyOTPUsingPOST(fromResendOtp);
      },
      respBody => {
        return respBody;
      }
    );

  }

  onBack() {
    this.successVerifyOtp.emit(null);
  }

}
