import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { OAuth2AccessToken, TokenEndpointService } from '@proflink/prof-link-springsec-web-ic-api-ts-angular';
import { AppState } from '@app/core/app-state';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/index';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router,
        private appState: AppState,
        private tokenService: TokenEndpointService) {
    }

    private addAuthHeader(req: any, accessToken: string): any {
        return req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + accessToken
            }
        });
    }


    private handleAuthError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            const rToken = localStorage.getItem('refresh_token');
            const deviceToken = localStorage.getItem('device-token');
            return this.appState.loginWithRefreshToken(rToken, deviceToken).pipe(
                switchMap((body) => {
                    const accessToken: string = (<any>body).access_token;
                    const refreshToken = (<any>body).refresh_token;
                    const appUserModel: IcProfileModel = <IcProfileModel>(<any>body).data;
                    this.appState.setCurrentUser(appUserModel);
                    localStorage.setItem('access_token', accessToken);
                    localStorage.setItem('refresh_token', refreshToken);
                    return next.handle(this.addAuthHeader(req, accessToken));
                }),
                catchError((error) => {
                    return of(error);
                })
            );
        } else if (err.status === 403) {
            window.location.href = '/login';
        }

        return of(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({ headers: req.headers.set('Accept-Language', localStorage.getItem('language') == 'en' ? 'EN' : 'TH') });

        return next.handle(req).pipe(
            catchError(err => this.handleAuthError(req, next, err))
        );
    }
}
