import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-foreigner-wait-approve',
  templateUrl: './register-foreigner-wait-approve.component.html',
  styleUrls: ['./register-foreigner-wait-approve.component.scss']
})
export class RegisterForeignerWaitApproveComponent implements OnInit {

  @Input("mobileNumber")
  set setMobileNumber(mobileNumber: string) {
    this.mobileNumber = this.transform(mobileNumber);
  }

  constructor(private _router: Router) { }

  mobileNumber = "";

  ngOnInit(): void {
  }

  transform(mobileNumber: string): string {
    if (!mobileNumber || mobileNumber.length < 10) {
      return mobileNumber;
    }

    return mobileNumber.slice(0, 3) + 'xxx' + mobileNumber.slice(6);
  }

  onClosed() {
    this._router.navigate(['login']);
  }

}
