<form [formGroup]="form" *ngIf="!callbackForm">
    <div class="form-group">

        <div class="custom-input">

            <div class="form-group m-0">
                <label>{{'PUBLISH.MOBILE_NUMBER' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="mobileNumber"
                    [ngClass]="{ 'is-invalid': form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.required}" />
                <span *ngIf="form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.required"
                    class="danger">
                    {{'PUBLISH.REQUIRE_MOBILE' | translate}}
                </span>
                <span *ngIf="form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.pattern"
                    class="danger">
                    {{'PUBLISH.INCORRECT_MOBILE' | translate}}
                </span>
            </div>

            <div class="form-group m-0">
                <label>{{'PUBLISH.EMAIL' | translate}}</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="email" [email]="true"
                    [ngClass]="{ 'is-invalid': form.controls.email?.touched && (form.controls.email.errors?.required || form.controls.email.errors?.email)}" />
                <span *ngIf="form.controls.email?.touched && form.controls.email.errors?.required" class="danger">
                    {{'PUBLISH.REQUIRE_EMAIL' | translate}}
                </span>
                <span *ngIf="form.controls.email?.touched && form.controls.email.errors?.email" class="danger">
                    {{'PUBLISH.INCORRECT_EMAIL' | translate}}
                </span>
                <p *ngIf="isShowResendEmail">{{'PUBLISH.DES_NOT_VERIFY' | translate}}
                    <a class="text-primary btn-email" (click)="resendEmail()">{{'BUTTON.CLICK' | translate}}</a>
                </p>
            </div>

            <div class="form-group">
                <button type="button" class="btn btn-block un-submit bg-primary" (click)="save()">{{'BUTTON.SAVE' | translate}}</button>
            </div>
        </div>
    </div>
</form>