<div class="card">
    <p-menubar [model]="items">
        <ng-template pTemplate="start">
            <div class="my-0 mx-1 logo">Prof.<span class="text-primary logo"> Link</span></div>
        </ng-template>
        <ng-template pTemplate="end">
            <div class="d-flex">
                <a class="navbar-brand">
                    <div class="col-sm-12 text-center" [routerLink]="'notification'">
                        <i class="pi pi-bell notif">
                            <span *ngIf="numNotification > 0" class="num">{{numNotification}}</span>
                        </i>
                    </div>
                </a>
                <!-- <p-menu #menu [popup]="true" [model]="profileItems" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-menu> -->
                <!-- <a class="navbar-brand" (click)="menu.toggle($event)">
                    <div class="col-sm-12 text-center">
                        <i class="pi pi-id-card"></i>
                    </div>
                </a> -->
                <!-- <button pButton pRipple type="button" icon="pi pi-id-card" class="p-button-lg p-button-rounded p-button-text p-button-plain" (click)="menu.toggle($event)"></button> -->
                <a class="navbar-brand">
                    <div class="col-sm-12 text-center text-dark" [routerLink]="'profile'">
                        <i class="pi pi-id-card"></i>
                    </div>
                </a>
                <app-language-switcher></app-language-switcher>
            </div>
        </ng-template>
    </p-menubar>
</div>