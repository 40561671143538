import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  @Input() defaultLang: string = 'th';

  @Input() isDisabled: boolean = false;

  languages = [
    {
      code: 'th',
      name: 'TH',
      flag: 'assets/image/th.png'
    },
    {
      code: 'en',
      name: 'EN',
      flag: 'assets/image/en.png'
    }
  ];

  currentLang: string;
  dropdownVisible = false;
  currentLangFlag: string;
  isDisabledLang = false;

  constructor(private translate: TranslateService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.defaultLang = localStorage.getItem('language');
    if (this.defaultLang == null) {
      this.defaultLang = 'th';
    }
    this.currentLang = this.defaultLang;
    this.currentLangFlag = this.languages.find(lang => lang.code === this.currentLang)?.flag;
    this.translate.setDefaultLang(this.currentLang);
    this.isDisabledLang = this.isDisabled;
  }

  switchLanguage(langCode: string): void {
    const selectedLang = this.languages.find(lang => lang.code === langCode);
    if (selectedLang) {
      this.currentLang = selectedLang.code;
      this.currentLangFlag = selectedLang.flag;
      this.translate.use(langCode);
      this.dropdownVisible = false;
      localStorage.setItem('language', langCode);
      window.location.reload();
    }
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

}
