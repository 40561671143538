<div class="card card-body">
    <h4>Confirm your information with Data from SEC</h4>

    <div class="card card-body box-value">
        <div><label class="header-box" for="name">Your Name </label>
            <span *ngIf="userName === userNameSec" class="fa fa-check-circle  fa-sm" aria-hidden="true"
                style="color: green; padding-left: 5px;"></span>
            <span *ngIf="userName !== userNameSec" class="fa fa-info-circle  fa-sm" aria-hidden="true"
                style="color: red; padding-left: 5px"></span>
        </div>
        <div class="value-in-box">
            <span>{{ userName }}</span>
        </div>
        <div class="value-in-box">
            <span *ngIf="userName === userNameSec" style="color: black;">{{userNameSec}}<span style="color: black;">
                    (SEC)</span></span>
            <span *ngIf="userName !== userNameSec" style="color: red"> {{userNameSec}}<span
                    style="color: black; padding-left: 5px;">(SEC)</span></span>
        </div>
    </div>

    <div class="card card-body  box-value">
        <div><label class="header-box" for="name">Nationality</label>
            <span *ngIf="nationality === nationalitySec" class="fa fa-check-circle  fa-sm" aria-hidden="true"
                style="color: green; padding-left: 5px;"></span>
            <span *ngIf="nationality !== nationalitySec" class="fa fa-info-circle  fa-sm" aria-hidden="true"
                style="color: red; padding-left: 5px"></span>
        </div>
        <div class="value-in-box">
            <span>{{ nationality }}</span>
        </div>
        <div class="value-in-box">
            <span *ngIf="nationality === nationalitySec" style="color: black;">{{nationalitySec}}<span
                    style="color: black;">
                    (SEC)</span></span>
            <span *ngIf="nationality !== nationalitySec" style="color: red"> {{nationalitySec}}<span
                    style="color: black; padding-left: 5px;">(SEC)</span></span>
        </div>
    </div>

    <div class="card card-body box-value">
        <div><label class="header-box" for="name">Your Identification (Passport No)</label>
            <span *ngIf="passportNo === passportNoSec" class="fa fa-check-circle  fa-sm" aria-hidden="true"
                style="color: green; padding-left: 5px;"></span>
            <span *ngIf="passportNo !== passportNoSec" class="fa fa-info-circle  fa-sm" aria-hidden="true"
                style="color: red; padding-left: 5px"></span>
        </div>
        <div class="value-in-box">
            <span>{{ passportNo }}</span>
        </div>
        <div class="value-in-box">
            <span *ngIf="passportNo === passportNoSec" style="color: black;">{{passportNoSec}}<span
                    style="color: black;">
                    (SEC)</span></span>
            <span *ngIf="passportNo !== passportNoSec" style="color: red"> {{passportNoSec}}<span
                    style="color: black; padding-left: 5px;">(SEC)</span></span>
        </div>
    </div>

    <div class="card card-body box-value">
        <div><label class="header-box" for="name">Your Identification (Passport Expiry Date)</label>
            <span *ngIf="expiryDate === expiryDateSec" class="fa fa-check-circle  fa-sm" aria-hidden="true"
                style="color: green; padding-left: 5px;"></span>
            <span *ngIf="expiryDate !== expiryDateSec" class="fa fa-info-circle  fa-sm" aria-hidden="true"
                style="color: red; padding-left: 5px"></span>
        </div>
        <div class="value-in-box">
            <span>{{ expiryDate }}</span>
        </div>
        <div class="value-in-box">
            <span *ngIf="expiryDate === expiryDateSec" style="color: black;">{{expiryDateSec}}<span
                    style="color: black;">
                    (SEC)</span></span>
            <span *ngIf="expiryDate !== expiryDateSec" style="color: red"> {{expiryDateSec}}<span
                    style="color: black; padding-left: 5px;">(SEC)</span></span>
        </div>
    </div>

    <div class="card card-body box-value">
        <div>
            <label class="header-box" for="approvalNo">Your Approval No.</label>
            <span class="fa fa-check-circle  fa-sm" aria-hidden="true" style="color: green; padding-left: 5px;"></span>
        </div>
        <div class="col value-in-box">
            <span>{{ approvalNo }}</span>
        </div>
    </div>

    <div class="d-flex" style="margin-bottom: 2rem;">
        <i class="fa fa-info-circle  fa-sm" aria-hidden="true"
            style="color: red; padding-right: 5px;padding-top: 5px;"></i>
        <label>Please re-check the information above before confirm your profile.</label>
    </div>


    <div>
        <input type="checkbox" id="confirmCheck" [(ngModel)]="isConfirmed" name="isConfirmed" required />
        <label for="confirmCheck" style="margin-left: 5px;">I've acknowledge and confirm the data from the SEC.</label>
    </div>
    <div class="form-group pt-3 pb-3">
        <button type="button" class="btn btn-secondary"
            style="width: 100%; background-color: #D9D9D9; border-color: #D9D9D9;" (click)="onBack()">
            Back
        </button>
    </div>

    <div class="form-group pt-0 pb-3">
        <button type="submit" [disabled]="!isConfirmed" class="btn btn-block btn-primary btn-next"
            style="color: white; background-color: #03A439; border-width: 0px;"
            (click)="confirmProfile()">Confirm</button>
    </div>



</div>