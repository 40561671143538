<div class="container">
    <div class="col-sm-12 px-0" style="background-color: #f8f9fb;">
        <img src="assets/image/Banner_Approved.png" alt="" class="w-100">
    </div>
    <div class="col-sm-12" style="background-color: #f8f9fb;">
        <h2 class="text-orange pb-0 pt-2 ml-3">การเผยแพร่ข้อมูลผ่าน TSI Website</h2>
        <hr style="border-color: #FBB034; border-width: medium;" class="my-0">
    </div>
    <div class="col-sm-12" style="background-color: #f8f9fb;">
        <div class="card card-body mt-2">

            <form [formGroup]="filterForm">
                <div class="filter-section">
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3">
                            <label for="trainingProvider">สถาบันจัดอบรม</label>
                            <ng-multiselect-dropdown [placeholder]="'Select All'"
                                formControlName="trainingProvider" [data]="listTrainingProvider"
                                [settings]="dropdownSettingsTrainingProvider" (onSelect)="onItemSelectTrainerProvider($event)"
                                (onDeSelect)="onItemDeSelectTrainerProvider($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-md-6 col-12 mb-3">
                            <label for="areaOfKnowledge">ด้านความรู้</label>
                            <ng-multiselect-dropdown [placeholder]="'Select All'"
                                formControlName="areaOfKnowledge" [data]="listOfCategory"
                                [settings]="dropdownSettingsCategory" (onSelect)="onItemSelectAreaOfKnowledge($event)"
                                (onDeSelect)="onItemDeSelectAreaOfKnowledge($event)">
                            </ng-multiselect-dropdown>
                        </div>

                    </div>


                    <div class="row">
                        <div class="col-md-6 col-12 mb-3">
                            <label for="courseName">ชื่อหลักสูตร</label>
                            <input type="text" id="courseName" formControlName="courseName" class="form-control"
                                />
                        </div>
                        <div class="col-md-6 col-12 mb-3 ml-auto" style="align-content: end; text-align: center;">
                            <button type="button" class="btn button-pending bg-primary mr-2" (click)="onSearch()">Search</button>
                            <button type="button" class="btn btn-secondary button-pending" (click)="onReset()"><img
                                    src="/assets/icons/reset.svg" />Reset</button>
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="table-container mt-1" >
            <table id="public-course" class="table table-bordered zero-configuration" style="width:100%">
                <thead style="background-color: white;">
                    <tr>
                        <th>ลำดับ</th>
                        <th>สถาบันจัดอบรม<br>ชื่อหลักสูตร</th>
                        <th>ด้านความรู้</th>
                        <th>วันที่มีผลบังคับใช้</th>
                        <th>สถานะ</th>
                    </tr>
                </thead>
                <tbody style="background-color: white;">
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <img src="assets/image/footer-public.png" alt="" class="w-100">
    </div>
</div>