import { Component, OnInit } from '@angular/core';
import { NewsModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/newsModel';
import * as WSCall from '@app/utils/WSCall';
import { PublicContactUsControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicContactUsController.service';
import { ProblemTopicModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/problemTopicModel';
import { Router } from '@angular/router';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { AppState } from '@core/app-state';
import { IcProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss']
})
export class ReportProblemComponent implements OnInit {

  constructor(
    private _router: Router,
    private _appState: AppState,
    private _icProfileControllerService: IcProfileControllerService,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  icProfile: IcProfileModel = null;
  icProfileId: number;

  reportProblemState: ReportProblemStateEnum = ReportProblemStateEnum.LIST;
  topicSelected: ProblemTopicModel;
  title: string = '';

  async ngOnInit() {
    this._translate.get('MENU_BAR.HELP').subscribe((res: string) => {
      this.title = res;
    });

    this._appState.loadCurrentUser();

    if (this._appState.currentUser != null) {
      let icProfile: IcProfileModel = this._appState.currentUser;
      this.icProfileId = icProfile.icId;
      console.log(this.icProfileId);
    }

    if (this.icProfile == null && this.icProfileId != null) {
      this.icProfile = await this.getIcProfileById(this.icProfileId);
    }

  }

  async onBackStateComplete() {

    if (this.reportProblemState == ReportProblemStateEnum.FORM) {
      this.reportProblemState = ReportProblemStateEnum.LIST;
    } else {
      await this._router.navigate(['home']);
    }
  }

  onCompleteSelected(selected: ProblemTopicModel) {
    this.topicSelected = selected;
    this.reportProblemState = ReportProblemStateEnum.FORM;
  }

  onSubmitComplete() {
    this.reportProblemState = ReportProblemStateEnum.LIST;
  }

  async getIcProfileById(icProfileId: number): Promise<IcProfileModel> {

    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getIcProfileByIdUsingGET(icProfileId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

}

export type ReportProblemStateEnum = 'LIST' | 'FORM'
export const ReportProblemStateEnum = {
  LIST: 'LIST' as ReportProblemStateEnum,
  FORM: 'FORM' as ReportProblemStateEnum,
}
