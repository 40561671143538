<app-header-with-back *ngIf="platform != 'mobile'" class="header" [title]="title"
    [path]="'profile'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div [ngClass]="passLearningResultCss">{{passLearningResultLabel}}</div>
        <div class="text-center mt-2">
            <label *ngIf="licenseCurrentPeriodResult != null" class="title">{{'LICENSE_STATUS_PAGE.T_NOTE' | translate}}
                
                {{language == 'th' ? licenseCurrentPeriodResult.startPeriod+543 :
                licenseCurrentPeriodResult.startPeriod}} - {{language == 'th' ? licenseCurrentPeriodResult.endPeriod+543
                : licenseCurrentPeriodResult.endPeriod}})</label>
        </div>
        <div class="card m-2 p-0">
            <div id="chartdiv" [style.width.%]="100" [style.height.px]="500"></div>
        </div>
        <div *ngIf="icLicenseSummaryV2Result != null" class="m-2 pt-2 d-flex flex-column">

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-0 d-flex flex-row justify-content-center">
                    <label class="title">{{'LICENSE_STATUS_PAGE.T_CONTITION' | translate}}</label>
                </div>
            </div>
            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition">

                    </div>
                </div>

                <div class="p-1 flex-column flex-fill">
                    <label class="text-condition-1" style="width: 150px;"></label>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-around">
                    {{'LICENSE_STATUS_PAGE.TOTAL_HOUR' | translate}}
                </div>
            </div>
            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-1">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">{{language == 'th' ? (('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) + '1') : '1' + ('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) }}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-2">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ETHIC' |
                            translate}}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">({{'LICENSE_STATUS_PAGE.LCS_CONDITION_REQUIRED' | translate}}
                            {{icLicenseSummaryV2Result.requiredEthicMinutes/60| number:'1.0-2'}} {{'PUBLISH.HOUR' |
                            translate}})</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">

                    <div v class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.ethicStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalEthicMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>


            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-2">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">{{language == 'th' ? (('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) + '2') : '2' + ('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) }}</label>
                    </div>
                    <div class="p-0">
                        <label class="text-condition-1">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_ESG' | translate}}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">({{'LICENSE_STATUS_PAGE.LCS_CONDITION_REQUIRED' | translate}}
                            {{icLicenseSummaryV2Result.requiredEsgMinutes/60| number:'1.0-2'}} {{'PUBLISH.HOUR' |
                            translate}})</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.esgStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalEsgMinutes/60| number:'1.0-2'}} </label>
                    </div>
                </div>
            </div>

            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-2">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_OTHERS' |
                            translate}}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_NOT_ETHIC_FOOTNOTE' |
                            translate}}</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.generalStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalGeneralMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>

            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-3">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">{{language == 'th' ? (('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) + '3') : '3' + ('LICENSE_STATUS_PAGE.NUMBER_CONTITION' |
                            translate) }}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-2">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_TOTAL' |
                            translate}}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">{{'LICENSE_STATUS_PAGE.LCS_CONDITION_REQUIRED' | translate}}
                            {{icLicenseSummaryV2Result.requiredMinutes/60|
                            number:'1.0-2'}}
                            {{'PUBLISH.HOUR' | translate}}</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.acquiredStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalAcquiredMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>
            <div class="p-3 text-muted remark-text">
                <label>{{'LICENSE_STATUS_PAGE.REMARK' | translate}}</label>
            </div>
            <div class="mb-3 text-center">
                <a class="text-warning" [routerLink]="'/profile/license-status-detail'">{{'LICENSE_STATUS_PAGE.DETAIL' |
                    translate}}</a>
            </div>
        </div>
    </div>
</div>