import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';

@Pipe({
  name: 'dateThai'
})
export class DateThaiPipe implements PipeTransform {

  transform(date: any, ...args: unknown[]): unknown {
    const language = localStorage.getItem('language');
    const dayMonth = new DatePipe('th-TH').transform(date, 'd MMM', null, language == 'th' ? null : 'en');
    const year = new DatePipe('th-TH').transform(date, 'yyyy');
    const finalYear = language == 'th' ? Number(year) + 543 : year;
    return dayMonth + ' ' + finalYear;
  }

}
