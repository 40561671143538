import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IcCompanyModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel';
import { IcProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import { ChangeIcCompanyLogRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeIcCompanyLogRequest';
import { CompanyControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/companyController.service';
import { CompanyModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/companyModel';
import * as Utils from '@app/utils/Utils';
import { IcChangedLogControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icChangedLogController.service';
import * as WSCall from '@app/utils/WSCall';
import { APIResultboolean } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean';
import { ValidateMoveCompanyResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/validateMoveCompanyResponse';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-profile-company-form',
    templateUrl: './profile-company-form.component.html',
    styleUrls: ['./profile-company-form.component.scss']
})
export class ProfileCompanyFormComponent implements OnInit {
    @Input() profile: IcProfileModel;
    @Input() companyStaff: IcCompanyModel[] = [];
    @Input() companyAgent: IcCompanyModel[] = [];

    @Output() editSuccess: EventEmitter<boolean> = new EventEmitter();

    companyStaffActive: IcCompanyModel[] = [];

    companyIdSelected: number = null;

    companyList: CompanyModel[] = [];
    public form = new FormGroup({
        changeType: new FormControl(null, Validators.required),
        companyId: new FormControl('')
    });
    buttonDisabled = true;

    constructor(private icProfileService: IcProfileControllerService,
        private companyService: CompanyControllerService,
        private icChangedLogControllerService: IcChangedLogControllerService,
        private _translate: TranslateService) {
    }

    ngOnInit(): void {
        this.companyService.getAllCompanyIgnoreCurrentUsingGET().subscribe((res) => {
            this.companyList = res.data;
        });

        if (this.profile != null) {
            this.companyStaffActive = this.profile.listIcCompanyStaff;
            console.log(this.companyStaffActive);
        }

        this.form.controls.changeType.valueChanges.subscribe(
            (value: string) => {
                this.buttonDisabled = false;
                this.form.get('companyId').setValidators(Validators.required);
                this.form.get('companyId').updateValueAndValidity();
            }
        );
    }

    sendRequest(): void {

        console.log(this.form.controls.companyId.value);
        const request: ChangeIcCompanyLogRequest = {
            changeType: 'N',
            companyId: this.companyIdSelected
        };

        this.icProfileService.changedCompanyUsingPOST(request).subscribe((res) => {
            if (res.resultCode === 'ERROR') {
                Utils.error(res.errorMessage, '');
            } else {
                this.editSuccess.emit(true);
            }
        });

    }

    async onChecked(event: Event, icCompanyModel: IcCompanyModel) {
        this.buttonDisabled = true;
        let input: HTMLInputElement = <HTMLInputElement>event.target;
        let companyIds: any = document.getElementsByName("companyId");
        companyIds.forEach(function (x: any) {
            if (x.id != input.id) {
                x.checked = false;
            }
        });

        if (!input.checked) {
            return;
        }

        let companyId: number = parseInt(input.value);
        this.companyIdSelected = companyId;
        let validateMoveCompany: ValidateMoveCompanyResponse = await this.canRequestMoveCompany(companyId);
        if (validateMoveCompany.canRequestMoveCompany) {
            let msgFirst = '';
            let msgSecond = '';
            this._translate.get('PROFILE_PAGE.C_DUPLICATE_1').subscribe((res: string) => {
                msgFirst = res;
            });
            this._translate.get('PROFILE_PAGE.C_DUPLICATE_2').subscribe((res: string) => {
                msgSecond = res;
            });
            let message = `${msgFirst} ${icCompanyModel.company.nameTh} ${msgSecond} `;
            this.showInfo(message);
            this.buttonDisabled = true;
            return;
        }

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-warning pl-3 pr-3 m-2",
                cancelButton: "btn btn-light pl-3 pr-3 m-2"
            },
            buttonsStyling: false
        });

        let msgConfirm1 = '';
        let msgConfirm2 = '';
        let btnConfirm = '';
        let btnCancel = '';
        this._translate.get('PROFILE_PAGE.C_COMPANY_1').subscribe((res: string) => {
            msgConfirm1 = res;
        });
        this._translate.get('PROFILE_PAGE.C_COMPANY_2').subscribe((res: string) => {
            msgConfirm2 = res;
        });
        this._translate.get('BUTTON.CONFIRM').subscribe((res: string) => {
            btnConfirm = res;
        });
        this._translate.get('BUTTON.CANCEL').subscribe((res: string) => {
            btnCancel = res;
        });

        swalWithBootstrapButtons.fire({
            text: `${msgConfirm1} ${icCompanyModel.company.nameTh}  ${msgConfirm2}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: btnConfirm,
            cancelButtonText: btnCancel,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.sendRequest();
            } else {
                companyIds.forEach(function (x: any) {
                    x.checked = false;
                });
            }
        });
    }

    async canRequestMoveCompany(companyId: number): Promise<ValidateMoveCompanyResponse> {
        return WSCall.wsCall(
            () => {
                return this.icChangedLogControllerService.canRequestMoveCompanyUsingGET(companyId);
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    showInfo(message: string) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-warning pl-3 pr-3 m-2",
                cancelButton: "btn btn-light pl-3 pr-3 m-2"
            },
            buttonsStyling: false
        });
        let btn = '';
        this._translate.get('BUTTON.ACKNOWLEDGE').subscribe((res: string) => {
            btn = res;
        });

        swalWithBootstrapButtons.fire({
            text: message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: btn
        });
    }

}
