import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountControllerService, SecPersonResponse } from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import { APIResultListSecPersonResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultListSecPersonResponse';


@Component({
  selector: 'app-search-approval-model',
  templateUrl: './search-approval-model.component.html',
  styleUrls: ['./search-approval-model.component.scss']
})
export class SearchApprovalModelComponent implements OnInit {

  @Input() name: string;
  constructor(public activeModal: NgbActiveModal,
    private _accountControllerService: AccountControllerService) { }

  ngOnInit(): void {
  }

  searchTerm: string = '';
  results: SecPersonResponse[] = [];
  canSearch: boolean = false;
  selectedResult: SecPersonResponse = null;


  onSearchChange() {
    if (this.searchTerm.length >= 4) {
      this.canSearch = true;
    } else {
      this.canSearch = false;
    }
  }

  search() {
    this.getSearchPersonaByName().then(resp => {
      this.results = resp.data;
    });

  }

  async getSearchPersonaByName(): Promise<APIResultListSecPersonResponse> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.searchPersonaByNameUsingGET(this.searchTerm);
      },
      respBody => {
        return respBody;
      }
    );
  }

  selectResult(result: SecPersonResponse) {
    this.selectedResult = result;
  }

  confirmSelection() {
    if (this.selectedResult) {
      console.log('Selected:', this.selectedResult);
      let approveNo = this.selectedResult['regis_sale_no'];
      this.activeModal.close(approveNo);
    }
  }

}
