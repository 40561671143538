<div class="wrapper" [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'profile'"></app-header-with-back>
    <div class="box-container" *ngIf="icProfile != null">
        <div class="box">
            <div class="content pb-4">
                <div class="d-flex flex-column card-custom-1">

                    <!-- profile -->
                    <div class="pt-0 pb-0 pr-0 pl-0 d-flex flex-row m-3 border-banner"
                        [style]="'background-color:'+bgColor">

                        <!-- profile name-->
                        <div class="p-0 d-flex flex-column flex-fill">
                            <div class="p-0 d-flex flex-column">
                                <div class="pt-3 pb-2">
                                    <label class="m-2 p-name">{{icProfile.firstnameTh + " "+
                                        icProfile.lastnameTh}}</label>
                                </div>

                                <div class="pt-3 m-2">
                                    <div class="p-0 d-flex flex-column">
                                        <div class="p-0">
                                            <label class="p-title-detail">{{'PROFILE_PAGE.T_CARD' | translate}}</label>
                                        </div>

                                        <div class="p-0">
                                            <label class="p-detail">{{icProfile.idCard}}</label>
                                        </div>
                                    </div>

                                    <div class="pt-3 d-flex flex-column">
                                        <div class="p-0">
                                            <label class="p-title-detail">{{'PROFILE_PAGE.T_LICENSE_NUMBER' |
                                                translate}}</label>
                                        </div>

                                        <div class="p-0">
                                            <label class="p-detail">{{icProfile.icCode}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- image profile -->
                        <div class="align-self-center flex-fill">
                            <!-- <div class="pb-5 flex-fill text-right m-0">
                                <div class="pb-3 m-0 pl-0 pr-0 p-logo text-center" style="background-color: white">
                                    <div class="pl-3">
                                        <img src="../../../../../assets/image/logo_ProfLink.png" width="90px"
                                            height="15px">
                                    </div>
                                </div>
                            </div> -->
                            <div class="text-center">
                                <img [src]="getFile(icProfile.attachedProfileFile)" class="rounded img-avartar"
                                    onerror="this.src='../../../../../assets/image/icon_avatar.png'">
                            </div>
                        </div>
                    </div>
                    <!-- icon -->
                    <div *ngIf="icProfile != null" class="d-flex justify-content-around">
                        <div *ngIf="icProfile.requireIdCard" class="text-center">
                            <button type="button" class="btn btn-sm border border-dark rounded-circle"
                                (click)=open(requireIdCard)>
                                <img src="../../../../../assets/icons/card-checklist.svg">
                            </button>
                            <div>{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' | translate}}</div>
                        </div>
                        <div *ngIf="icProfile.requireDoc" class="text-center">
                            <button type="button" class="btn btn-sm border border-dark rounded-circle"
                                (click)="open(requireDoc)">
                                <img src="../../../../../assets/icons/file-earmark-text.svg">
                            </button>
                            <div>{{'NOTIFICATION_PAGE.NOTIFICATION_ACTION_SESSION_DETAIL' | translate}}</div>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-sm rounded-circle btn-primary"
                                [routerLink]="'/profile/checkin-checkout-online'">
                                <img src="../../../../../assets/icons/camera.svg">
                            </button>
                            <div>Check In/Out</div>
                        </div>
                    </div>

                    <!-- qr code -->
                    <div class="p-0 m-0 d-flex justify-content-center">
                        <div class="d-flex flex-column">
                            <div class="title-topic text-center p-3">{{'DIGITAL_PASSPORT_PAGE.T_QR' | translate}}</div>
                            <div *ngIf="disableQrCode" class="p-0 flex-fill qr-display d-flex justify-content-center">
                                <div class="bg-qr">
                                    <p *ngIf="flagExpired" class="text-qr-1">
                                        {{'DIGITAL_PASSPORT_PAGE.STATUS_CHANGE' | translate}}
                                    </p>
                                    <p *ngIf="!flagExpired" class="text-qr-2">
                                        {{'DIGITAL_PASSPORT_PAGE.STATUS_WAIT' | translate}}</p>
                                </div>
                                <img class="img-qr" src="../../../../../assets/image/ic_qr_scan.png" width="250px"
                                    height="250px">
                            </div>
                            <div *ngIf="!disableQrCode" class="p-0 text-center">
                                <qrcode [qrdata]="qrCodeIcProfile" [width]="256" [errorCorrectionLevel]="'M'"
                                    allowEmptyString="true">
                                </qrcode>
                            </div>
                            <div *ngIf="icProfile.digitalPassportExpiredDate" class="text-center">
                                {{'DIGITAL_PASSPORT_PAGE.DATE_EXP' | translate}} :
                                {{getDisplayDate(icProfile.digitalPassportExpiredDate)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #requireDoc let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{'MENU_BAR.DIGITAL_PASSPORT' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card card-body col-md-12 col-sm-12">
                <div class="text-center">
                    <img src="../../../../../assets/icons/info-circle.svg">
                </div>

                <p></p>
                <div class="title-header font-weight-bold">{{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_TITLE_1' | translate}}
                </div>
                <div class="text-mute">
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_1' | translate}}
                </div><br>
                <div class="title-header font-weight-bold">{{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_TITLE_2' | translate}}
                </div>
                <div class="text-mute">
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_2_TITLE_1' | translate}}
                </div>
                <div class="text-mute">
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_2_TITLE_2' | translate}}
                </div><br>
                <div class="title-header font-weight-bold">{{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_TITLE_3' | translate}}
                </div>
                <div class="text-mute">
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_3_1' | translate}}<br>
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_3_2' | translate}}<br>
                    3{{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_3_3' | translate}}<br>
                    {{'DIGITAL_PASSPORT_PAGE.INFO_HOLD_DES_3_4' | translate}}
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #requireIdCard let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{'MENU_BAR.DIGITAL_PASSPORT' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card card-body col-md-12 col-sm-12">
                <div class="text-center">
                    <img src="../../../../../assets/icons/info-circle.svg">
                </div>
                <p></p>
                <div class="title-header font-weight-bold">{{'DIGITAL_PASSPORT_PAGE.INFO_DETAIL_TITLE_1' | translate}}
                </div>
                <div class="text-mute">
                    {{'DIGITAL_PASSPORT_PAGE.INFO_DETAIL_DES_1' | translate}}</div>
                <p></p>
                <div class="title-header font-weight-bold">{{'DIGITAL_PASSPORT_PAGE.INFO_DETAIL_TITLE_2' | translate}}
                </div>
                <div class="text-mute">{{'DIGITAL_PASSPORT_PAGE.INFO_DETAIL_DES_2' | translate}}</div>
            </div>
        </div>
    </ng-template>
</div>